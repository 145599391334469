import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { selectContactNotesSelector } from '../../contactsSlice/contactsSelector';
import { addNewNote, fetchEditNote } from '../../contactsSlice/contactsSlice';
import PopUpAction from '../PopUpAction';
import './NoteForm.scss';


type NoteFormProps = {
  contactId: number;
  noteId?: number;
  togglePopUp: () => void;
};

function NoteForm({ contactId, noteId, togglePopUp }: NoteFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const selectContactNotes = useSelector(selectContactNotesSelector);
  const methods = useForm<{text: string}>({ mode: 'onSubmit', defaultValues: { text: '' } });

  useEffect(() => {
    if (noteId) {
      const objNote = selectContactNotes?.find((note) => note.id === noteId);
      if (objNote) {
        methods.reset({ text: objNote.text });
      }
    }
  }, []);

  const onCancel = () => {
    methods.reset();
    togglePopUp();
  };

  const onSubmit = (data: {text: string}) => {
    if (data && !noteId) {
      dispatch(addNewNote({ companyId, contactId, text: data.text }));
      togglePopUp();
    } else if (noteId) {
      dispatch(fetchEditNote({ companyId, contactId, noteId, text: data.text }));
      togglePopUp();
    }
  };
  
  return (<>
    <form>
      <PopUpAction
        title={noteId ? t('edit') : t('contacts.detailContact.addNote')}
        actionButtonCaption={noteId ? t('save') : t('add')}
        cancelButton={onCancel}
        onSubmit={methods.handleSubmit(onSubmit)}
        disabled={methods.formState.isDirty}
        >
        <div className="noteForm">
          <textarea className='smallText'
            placeholder={t('contacts.detailContact.textNote')} {...methods.register('text')} />
        </div>
      </PopUpAction>
    </form>
  </>);
};

export default NoteForm;
