import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router";
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { SubscriptionsSelector } from '../../SubscriptionsSelector';
import { selectContext } from "../../SubscriptionContext";
import { bdAnchorsSupport } from '../../../../../pages/Home/utils/consts';
import UserSubscriptions from '../../models/UserSubscriptions';
import Button from '../../../../../components/Button/Button';
import SidePanel from '../../../../../components/Modals/SidePanel';
import SubscriptionsInvoicePay from '../SubscriptionsInvoicePay';
import SubscriptionsList from '../SubscriptionList';
import SubscriptionsInvoices from '../SubscriptionsInvoices';
import ActionDialog from '../../../../../components/Modals/ActionDialog';


type SubscriptionViewProps = {
  setSwitchPosition: React.Dispatch<React.SetStateAction<string>>;
};

function SubscriptionView({ setSwitchPosition }: SubscriptionViewProps) {
  const { t, i18n } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const subscriptions = useSelector(SubscriptionsSelector);
  const [subscriptionsList, setSubscriptionsList] = useState<UserSubscriptions>();
  const [disabledPay, setDisabledPay] = useState<boolean>(false);
  const [onPay, setOnPay] = useState<boolean>(false);
  const [paylockedWarn, setPaylockedWarn] = useState<boolean>(false);
  const [dialogOnly, setDialogOnly] = useState<boolean>(false);
  const location = useLocation<boolean>();
  const { state } = location;

  const checkSelected = useCallback(()=> {
    if (currentCompany.paymentsLocked) {
      setDisabledPay(currentCompany.paymentsLocked);
    } else {
      setDisabledPay(subscriptionsList?.selected.length === 0);
    }
  }, [currentCompany, subscriptionsList]);

  useLayoutEffect(() => {
    checkSelected();
  }, [currentCompany, subscriptionsList]);

  useEffect(() => {
    if (subscriptionsList) {
      setSubscriptionsList((prev) => {
        let updateList = prev;
        if (updateList) {
          const stateSubscriptions = updateList.userSubscriptions;
          updateList = new UserSubscriptions(subscriptions);
          updateList.updateSubscriptions(stateSubscriptions);
        }
        return updateList;
      });
    } else {
      setSubscriptionsList(new UserSubscriptions(subscriptions));
    }
  }, [subscriptions]);

  useEffect(() => {
    if (state) setOnPay(!onPay);
  }, []);

  const onClickPay = () => {
    if (subscriptionsList) {
      setDialogOnly(false);
      setOnPay(!onPay);
    }
  };

  const payDialog = () => {
    setDialogOnly(true);
    setOnPay(true);
  };

  const onClickPayLocked = () => {
    if (currentCompany?.paymentsLocked) setPaylockedWarn(!paylockedWarn);
  };

  const onClickAction = () => {
    window.open(bdAnchorsSupport[i18n.language], '_blank');
  };

  if (subscriptionsList) {
    return (<>
      {paylockedWarn &&
        <ActionDialog title={t('subscriptions.pay_locked_warn.head')} message={t('subscriptions.pay_locked_warn.line')}
          action={t('subscriptions.pay_locked_warn.support')} cancel={t('cancel')}
          setIsOpen={setPaylockedWarn} onAction={onClickAction} />
      }
      {onPay &&
        <SidePanel setIsOpen={setOnPay} background='light'>
          <SubscriptionsInvoicePay setOnPay={setOnPay} subscriptionsList={subscriptionsList.selected}
            setSwitchPosition={setSwitchPosition} dialogOnly={dialogOnly} />
        </SidePanel>
      }
      {currentCompany?.paymentsLocked ? (
        <div className="subscriptions__button_disable" onClick={onClickPayLocked}>{t('subscriptions.pay')}</div>
      ) : (
        <Button text={t('subscriptions.pay')} textType='regular' color='orange'
          onClick={onClickPay} disabled={disabledPay} />
      )}
      {/* use context for check if no one subscription select, so disable pay button */}
      <selectContext.Provider value={{ checkSelected, setPay: payDialog }}>
        <SubscriptionsList subscriptions={subscriptionsList.userSubscriptions} />
      </selectContext.Provider>
      <SubscriptionsInvoices />
    </>);
  }
  return null;
}

export default SubscriptionView;
