import { FileMessage, MessageTemplateWabaCarouselParams, TextMessage, WabaTemplateMessage } from "../../api/messages/types";


export type BroadcastStatusType = 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'FAILED';

export type BroadcastErrorType = 'CONNECTION_NOT_IN_SUBSCRIPTION' | 'SUBSCRIPTION_NOT_PAID' | 'GENERIC_ERROR';

export const conditionsLogicOperations = ['and', 'or'] as const;

export type ConditionsLogicOperations = typeof conditionsLogicOperations[number];

export type ConditionType = 'tag';

export type ComparisionType = '=' | '!=';

export interface ICondition {
  conditionType: ConditionType;
  comparison: ComparisionType;
  value: number;
}

export interface IConditions {
  logicOperation: ConditionsLogicOperations;
  conditions: ICondition[];
}

export interface IChats {
  chats: string[];
}

export interface IText {
  text: string;
}

export interface IFile {
  caption: string;
  url?: string;
}

export interface IHeaderTemplate {
  text?: string;
  url?: string;
}

export interface IBroadcastParam {
  fromActiveChat: boolean;
  conditions: IConditions | IChats;
}

export type IBroadcastTypeMessage = 'text' | 'file' | 'image' | 'video' | 'waba_template';

export interface IBroadcastMessage {
  messageType: IBroadcastTypeMessage;
  text?: IText;
  file?: IFile;
  image?: IFile;
  audio?: IFile;
  video?: IFile;
  voice?: IFile;
  wabaTemplate?: IWabaTemplateParam;
}

export interface IWabaTemplateCarousel {
  headerUrl: string;
  bodyParams?: string[];
  buttonParams?: string[];
}

export interface IWabaTemplateParam {
  templateLocale: string;
  templateName: string;
  headerParams?: IHeaderTemplate[];
  bodyParams?: string[];
  buttonsParams?: string[];
  carouselMediaCardParams?: IWabaTemplateCarousel[];
}

export interface IStatistic {
  total: number;
  sent: number;
  delivered: number;
  read: number;
  error: number;
}

export type IBroadcastDisplayMessage = {
  text?: TextMessage;
  file?: FileMessage;
  image?: FileMessage
  audio?: FileMessage;
  video?: FileMessage;
  voice?: FileMessage;
  wabaTemplate?: WabaTemplateMessage;
};

export interface IBroadcast {
  id: number;
  name: string;
  connectionId: number;
  params: IBroadcastParam;
  message: IBroadcastMessage;
  plannedTime?: string;
  status: BroadcastStatusType;
  statistics: IStatistic;
  displayMessage: IBroadcastDisplayMessage;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  errorType: BroadcastErrorType;
}

export interface IStatistics {
  chatId: number;
  inputChatId: string;
  errorType: string;
  deliveredAt: string;
  readAt: string;
  errorAt: string;
}

export interface IMetadata {
  count: number;
  totalCount: number;
}

export interface IBroadcasts {
  responseMetadata: IMetadata;
  broadcasts: IBroadcast[];
}

export interface IBroadcastsStatistics {
  responseMetadata: IMetadata;
  statistics: IStatistics[];
}

export type IBroadcastRequest = {
  companyId: number;
  broadcastId: number;
};

export type BroadcastsRequest = {
  companyId: number;
  limit?: number;
  offset?: number;
  statuses?: BroadcastStatusType | string[];
};

export type BroadcastsStatisticsRequest = {
  broadcastsId: number;
  companyId: number;
  limit?: number;
  offset?: number;
};

export type ConditionItemType = {
  condition_type: ConditionType;
  comparison: ComparisionType;
  value: number;
};

export type ConditionsType = {
  logic_operation: ConditionsLogicOperations;
  conditions: ConditionItemType[];
};

export type BroadcastParamsType = {
  from_active_chat: boolean;
  conditions: ConditionsType | IChats | null;
};

export interface IBroadcastMessageCreate {
  message_type: IBroadcastTypeMessage;
  text?: IText;
  file?: IFile;
  image?: IFile;
  audio?: IFile;
  video?: IFile;
  voice?: IFile;
  waba_template?: IWabaTemplateParamCreate;
}

export interface IWabaTemplateParamCreate {
  template_locale?: string;
  template_name?: string;
  header_params?: IHeaderTemplate[] | TextConvertHeaderType[];
  body_params?: string[];
  carousel_media_card_params?: MessageTemplateWabaCarouselParams[];
}

export type BroadcastCreateRequest = {
  companyId: number;
  name: string;
  connection_id: number;
  params: BroadcastParamsType;
  message: IBroadcastMessageCreate;
  planned_time?: Date;
};


export type BroadcastUpdateRequest = BroadcastCreateRequest & {
  broadcastId: number;
};

export type BroadcastStopRequest = IBroadcastRequest & {
  status: 'FINISHED';
};

export type BroadcastRecipientsRequest = {
  companyId: number;
  connectionId: number;
  from_active_chat: boolean;
  conditions?: ConditionsType | IChats;
};

export interface IBroadcactRecipients {
  estimatedRecipientsCount: number;
}

export type ChatConnectionsType = 'odnoklassniki' | 'whatsapp' | 'waba' | 'telegram' | 'telegram_bot';

export type ChatSubscriptionType = {
  id: number;
  paidUntil: string;
};

export interface ISourceType {
  connectionId: number;
  subscription: ChatSubscriptionType;
  name: string;
  type: ChatConnectionsType;
  createChat: {
    withPhone: boolean;
    withUsername: boolean;
  };
}

export type InputCollection = string[];

export type TextConvertHeaderWithUrlType = {
  url?: string;
  text?: string;
};

export type TextConvertHeaderType = {
  text: string;
};

export type DialogsCountsType = {
  type: 'WABA_DIALOGS';
  value: number;
};
