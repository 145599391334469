

export const tagsColors = [
  { id: 1, color: '#FFFFFF' },
  { id: 2, color: '#FABBBC' },
  { id: 3, color: '#99D7CC' },
  { id: 4, color: '#FFB7D6' },
  { id: 5, color: '#FFD5AB' },
  { id: 6, color: '#D2D2D2' },
  { id: 7, color: '#D3E5F4' },
  { id: 8, color: '#9FE2EB' },
  { id: 9, color: '#F6F8C5' },
  { id: 10, color: '#E6F9D4' },
  { id: 11, color: '#FFCED4' },
  { id: 12, color: '#D0C0E3' },
  { id: 13, color: '#ECECEC' },
];
