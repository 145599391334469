import { useEffect, useState } from 'react';
import './TemplateWabaCarousel.scss';
import { WabaTemplateMessageCard } from '../../data';
import TemplateWabaButtons from '../TemplateWabaButtons';
import TemplateWabaHeader from '../TemplateWabaHeader';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/arrow-left.svg';


type Props = {
  carousel: WabaTemplateMessageCard[];
};

const TemplateWabaCarousel = ({ carousel }: Props) => {
  const [cardIndex, setCardIndex] = useState<number>(0);
  const card = carousel[cardIndex];
  
  useEffect(() => {
    if (carousel.length < cardIndex + 1) setCardIndex(0);
  }, [carousel]);

  const onChangeCard = (e: React.MouseEvent, step: number) => {
    e.stopPropagation();
    setCardIndex((prev) => {
      if (prev + step < carousel.length && prev + step >= 0) {
        return prev + step;
      }
      return prev;
    });
  };

  if (!card) {
    return <></>;
  }

  return (
    <div className="templateWabaCarousel">
      <div className="templateWabaCarousel__content">
        <TemplateWabaHeader header={card.header} />
        <p className="smallText">{card.body}</p>
        <button className="back" onClick={(e) => onChangeCard(e, -1)} disabled={cardIndex === 0}>
          <ArrowIcon />
        </button>
        <button className="forward" onClick={(e) => onChangeCard(e, 1)} disabled={cardIndex === carousel.length - 1}>
          <ArrowIcon />
        </button>
      </div>
      <div className="templateWabaCarousel__buttons">
        {card.buttons && <TemplateWabaButtons buttons={card.buttons} />}
      </div>
    </div>
  );
};

export default TemplateWabaCarousel;
