import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { wabaTemplateButtonTextLimit } from '../../../utils/waba';


export function useWabaTemplateAuthSchema() {
  const { t } = useTranslation();

  return yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    add_security_recommendation: yup.boolean(),
    code_expiration_minutes: yup.number().nullable(true).transform((_, val) => (val !== "" ? Number(val) : null))
      .min(1, t('error.form.min_value', { limit: 1 })).max(90, t('error.form.max_value', { limit: 90 })),
    buttons: yup.array().of(yup.object().shape({
      type: yup.string().required(),
      text: yup.string().required(t('error.form.empty_field')).when('type', {
        is: 'COPY_CODE' || 'ONE_TAP',
        then: yup.string().max(wabaTemplateButtonTextLimit.AUTHENTICATION.text, ({ max }) =>
          t('error.form.max_length', { limit: max })),
        otherwise: yup.string().max(wabaTemplateButtonTextLimit.AUTHENTICATION.text,  ({ max }) =>
          t('error.form.max_length', { limit: max }))
      })
    })).min(1, t('error.form.empty_field'))
  });
}
