import './TemplateWabaBody.scss';


type TemplateWabaBodyProps = {
  body: string;
};

const TemplateWabaBody = ({ body }: TemplateWabaBodyProps) => {
  return (
    <div className="templateWabaBody">
      <p className="smallText">{body}</p>
    </div>
  );
};

export default TemplateWabaBody;
