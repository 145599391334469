import './TagsPlug.scss';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import tagsDude from '../../../../assets/TagsDude.png';
import Button from '../../../../components/Button';
import React from 'react';


type Props = {
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagsPlug = ({ setIsDisable }: Props) => {
  const { t } = useTranslation();
  const permissionCreate = useHasPermission('tagsCreate');

  return (
    <div className="tagsPlug">
      <img className="tagsPlug__dude" src={tagsDude} alt="tag dude" />
      <h1>{t('settings.tags.title')}</h1>
      {permissionCreate ? (
        <>
          <p className='regularText'>{t('settings.tags.noTag')}</p>
          <Button
            text={t('settings.tags.create_button')}
            color="orange" textType="regular"
            image={<PlusIcon />}
            onClick={() => setIsDisable(true)}
          />
        </>
      ) : (
        <p className='regularText'>{t('settings.tags.noTagCreate')}</p>
      )}
    </div>
  );
};

export default TagsPlug;
