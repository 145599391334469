import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from './TagsAPI';
import * as typ from './TagsTypes';


export type InitialState = {
  tags: typ.Tag[];
  tag: typ.Tag | null;
  companyId: number;
  hasMore: boolean;
};

const initialState: InitialState = {
  tags: [],
  tag: null,
  companyId: 0,
  hasMore: true
};

type TagType = {
  tag_id: number;
  company_id: number;
};

export const getTagsList = createAsyncThunk<typ.Tag[], typ.TagsListRequestType, { rejectValue: number }>(
  'tags/getTagsList',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await api.getTags(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const getItemTag = createAsyncThunk<typ.Tag, { tagId: number; companyId: number }, { rejectValue: number }>(
  'tags/getItemTag',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await api.getTag(requestOption.tagId, requestOption.companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const postCreateTags = createAsyncThunk<typ.Tag, typ.CreateTag, { rejectValue: number }>(
  'tags/createTags',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await api.createTag(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const putUpdateTag = createAsyncThunk<typ.Tag, typ.UpdateTag, { rejectValue: number }>(
  'tags/patchUpdateTag',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await api.updateTag(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const deleteTags = createAsyncThunk(
  'tags/deleteTags', async (requestOptions: TagType, { dispatch, rejectWithValue }) => {
    try {
      await api.deleteTag(requestOptions.tag_id, requestOptions.company_id);
      return dispatch(deleteTagSlice({ id: requestOptions.tag_id }));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    deleteTagSlice: (state, action: PayloadAction<{ id: number }>) => {
      if (state.tags) {
        const idIndex = state.tags.findIndex((tag) => tag.id === action.payload.id);
        state.tags.splice(idIndex, 1);
      }
    },
    clearTag: (state) => {
      state.tag = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTagsList.fulfilled, (state, action) => {
        if (action.meta.arg.limit) {
          if (action.meta.arg.offset) {
            state.tags.push(...action.payload.slice(0, action.meta.arg.limit));
          } else {
            state.tags = action.payload.slice(0, action.meta.arg.limit);
          }
          state.hasMore = action.meta.arg.limit < action.payload.length;
        } else {
          state.tags = action.payload;
        }
      })
      .addCase(getItemTag.fulfilled, (state, action: PayloadAction<typ.Tag>) => {
        state.tag = action.payload;
      })
      .addCase(postCreateTags.fulfilled, (state, action) => {
        if (state.tags) {
          state.tags.push(action.payload);
        }
      })
      .addCase(putUpdateTag.fulfilled, (state, action) => {
        if (state.tags) {
          const idIndex = state.tags.findIndex((tag) => tag.id === action.payload.id);
          state.tags[idIndex] = action.payload;
        }
      });
  },
});

export const { deleteTagSlice, clearTag } = tagsSlice.actions;

export default tagsSlice.reducer;
