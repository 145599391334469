import './TemplateWabaFooter.scss';


type Props = {
  footer: string;
};

const TemplateWabaFooter = ({ footer }: Props) => {
  return (<>
  {footer &&
    <div className="templateWabaFooter">
      <p className="smallText">{footer}</p>
    </div>
  }</>);
};

export default TemplateWabaFooter;
