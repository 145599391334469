import { useTranslation } from 'react-i18next';
import { formatNumberLocale } from '../../../../utils/utils';
import './ItemStatistic.scss';


type ItemStatisticProps = {
  quantity: number;
  percentage?: number;
};

function ItemStatistic({ quantity, percentage }: ItemStatisticProps) {
  const { i18n } = useTranslation();

  return (
    <div className='itemStatistic'>
      <div className='itemStatistic__quantity'>{formatNumberLocale(quantity, 0, i18n.language)}</div>
      {percentage !== undefined && 
        <div className='itemStatistic__percentage'>{formatNumberLocale(percentage, 1, i18n.language)}%</div>}
    </div>
  );
};

export default ItemStatistic;
