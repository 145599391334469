import './ChatWabaTemplateFileType.scss';
import { ReactComponent as PDFIcon } from '../../../../../../../../assets/pdf.svg';
import VideoView from '../../../../../../../../components/VideoView';


type Props = {
  type: string;
  attachmentFile: File;
};

const ChatWabaTemplateFileType = ({ type, attachmentFile }: Props) => {
  switch (type) {
    case 'image':
      return <img alt="preview" className="imagePreview" src={URL.createObjectURL(attachmentFile)} />;
    case 'video':
      return <VideoView attachedFile={attachmentFile} />;
    case 'document':
      return (
        <div className="documentPreview">
          {attachmentFile.type === 'application/pdf' && <PDFIcon width={60} height={60} />}
          <span className="smallText">
            {attachmentFile.name.length > 20 ? attachmentFile.name.slice(0, 20) + '...' : attachmentFile.name}
          </span>
        </div>
      );
    default:
      return <></>;
  }
};

export default ChatWabaTemplateFileType;
