import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store/store';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { createBroadcast, updateBroadcast, getBroadcast, clearBroadcast } from '../BroadcactsSlice';
import { broadcastsItemSelector } from '../BroadcastsSelector';
import {
  BroadcastCreateRequest, ConditionsLogicOperations, ConditionsType,
  IBroadcastMessageCreate, IChats, ICondition
} from '../BroadcastsTypes';
import { uploadFile } from "../../Chat/ChatAPI";
import {
  ContactSourceType, BroadcastSchema, ErrorStateType, BroadcastMessage, BroadcastMessageFile,
  BroadcastMessageWabaTemplate
} from './types';
import { addAlertWithCustomText } from "../../../components/Alert/alertSlice";


export function useBroadcastItem(broadcastId?: number) {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const broadcastItem = useSelector(broadcastsItemSelector);

  useEffect(() => () => {
    dispatch(clearBroadcast());
  }, []);

  useEffect(() => {
    if (broadcastId) dispatch(getBroadcast({ companyId: currentCompany.id, broadcastId }));
  }, [broadcastId]);

  return broadcastItem;
}

export type BroadcastSubmitType = {
  data: BroadcastSchema;
  broadcastMessage: BroadcastMessage;
  broadcastId?: number;
  contactSource: ContactSourceType;
  recipients?: string[];
  operation: ConditionsLogicOperations;
  conditions?: ICondition[];
  recipientsEstimated?: number;
};

export type ResponseType = {
  status: boolean;
  error: ErrorStateType;
};

export function useSubmitBroadcast() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const onSubmitBroadcast = async (submitData: BroadcastSubmitType ) => {
    const { data, broadcastId, contactSource, recipients, conditions, operation, broadcastMessage,
      recipientsEstimated } = submitData;
    let response: ResponseType = {
      status: false,
      error: { text: false, template: false, recipients: false, recipientsRadist: false }
    };
    // contacts conditions
    let conditionsParams: ConditionsType | IChats | null = null;
    if (contactSource === 'radist' && conditions && conditions.length > 0) {
      // conditions for radist contacts
      conditionsParams = {
        logic_operation: operation,
        conditions: conditions.map(item => ({
          comparison: item.comparison, condition_type: item.conditionType, value: item.value }))
      };
    }
  
    if (contactSource === 'client' && recipients) {
      // contact list
      conditionsParams = { chats: recipients };
    }
    
    // message submit
    let messageFileUrl: string = '';
    let messageSubmit: IBroadcastMessageCreate | undefined;
    if (broadcastMessage.message_type === 'text') {
      messageSubmit = { message_type: 'text', text: { text: broadcastMessage.text } };
    }
    if (['image', 'video', 'file'].includes(broadcastMessage.message_type)) {
      const messageFile = (broadcastMessage as BroadcastMessageFile);
      if (messageFile.file !== undefined) {
        if (typeof messageFile.file === 'string') {
          messageFileUrl = messageFile.file;
        } else {
          await uploadFile({ file: messageFile.file, companyId: currentCompany.id })
            .then((url) => {messageFileUrl = url.url});
        }
        messageSubmit = {
          message_type: messageFile.message_type,
          [messageFile.message_type]: { caption: messageFile.caption, url: messageFileUrl }
        };
      }
    }
    if (broadcastMessage.message_type === 'waba_template') {
      const wabaTemplate = (broadcastMessage as BroadcastMessageWabaTemplate);
      const headerUrls = [];
      if (wabaTemplate.waba_template.header_params && wabaTemplate.waba_template.header_params?.length > 0) {
        const files = wabaTemplate.waba_template.header_params?.filter((param) => param.file)
        if (files?.length > 0) {
          for (const file of files) {
            if (file.file === undefined) continue;
            if (typeof file.file === 'string') {
              messageFileUrl = file.file;
            } else {
              await uploadFile({ file: file.file, companyId: currentCompany.id })
                .then((url) => {messageFileUrl = url.url});
            }
            headerUrls.push({ url: messageFileUrl });
          }
        }
      };
      const cardsHeaderUrls: string[] = [];
      if (wabaTemplate.waba_template.carousel_media_card_params
        && wabaTemplate.waba_template.carousel_media_card_params?.length > 0) {
        const headers = wabaTemplate.waba_template.carousel_media_card_params?.filter((param) => param.header)
        if (headers?.length > 0) {
          const requests: Array<Promise<{ url: string }>> = [];
          for (const header of headers) {
            if (header.header === undefined) continue;
            if (typeof header.header === 'string') {
              cardsHeaderUrls.push(header.header);
            } else {
              requests.push(uploadFile({ file: header.header, companyId: currentCompany.id }));
            }
          }
          if (requests.length > 0) {
            const responses = await Promise.all(requests);
            responses.forEach((res) => {
              cardsHeaderUrls.push(res.url ?? '');
            });
          }
        }
      }
      messageSubmit = {
        message_type: 'waba_template',
        waba_template: {
          template_name: wabaTemplate.waba_template.template_name,
          template_locale: wabaTemplate.waba_template.template_locale,
          body_params: wabaTemplate.waba_template.body_params,
          header_params: wabaTemplate.waba_template.header_params?.filter((param) => param.text),
          ...(headerUrls.length > 0 && { header_params: headerUrls }),
          ...(cardsHeaderUrls.length > 0 && {
            carousel_media_card_params: wabaTemplate.waba_template.carousel_media_card_params?.map((card, index) => (
              {
                body_params: card.body_params,
                button_params: card.button_params,
                header_url: cardsHeaderUrls[index]
              }
            )) })
        }
      };
    }
    
    const isEmptyConditions = contactSource === 'client' ? Boolean(recipients && recipients?.length === 0) : false;
    const isEmptyRecipients = contactSource === 'radist' ? !recipientsEstimated : false;
    const isEmptyMessage = messageSubmit?.message_type === 'text' ? !messageSubmit.text?.text : !messageSubmit;
    const isEmptyTemplate = messageSubmit === undefined;

    if (messageSubmit) {
      const dataPrepared: BroadcastCreateRequest = {
        companyId: currentCompany.id,
        ...{ ...data, params: { ...data.params, conditions: conditionsParams },
        message: messageSubmit }
      };
      
      if (!isEmptyConditions && !isEmptyRecipients && !isEmptyTemplate && !isEmptyMessage) {
        if (broadcastId) {
          await dispatch(updateBroadcast({ ...dataPrepared, broadcastId })).then(res => {
            response = { ...response, status: res.meta.requestStatus === 'fulfilled' };
          });
        } else {
          await dispatch(createBroadcast(dataPrepared)).then(res => {
            response = { ...response, status: res.meta.requestStatus === 'fulfilled' };
          });
        }
      } else dispatch(addAlertWithCustomText({ message: t('error.form.has_empty_fields'), type: 'alarm' }));
    };

    return { ...response, error: {
      text: isEmptyMessage, template: isEmptyTemplate,
      recipients: isEmptyConditions, recipientsRadist: isEmptyRecipients
     } };
  };

  return onSubmitBroadcast;
}
