import { useEffect, useReducer, useState } from 'react';
import './WabaTemplateMessage.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { broadcastsWabaTemplatesSelector } from '../../BroadcastsSelector';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { getBroadcastWabaTemplates } from '../../BroadcactsSlice';
import { BroadcastMessage } from '../../api/types';
import { SourceType } from '../../utils';
import { hasWabaTemplateParams, initialState, wabaTemplateContext, wabaTemplateMessageReducer, WabaTemplateParamsUpdate } from './data';
import { IBroadcastMessage } from '../../BroadcastsTypes';
import Button from '../../../../components/Button';
import ModalWrapper from '../../../../components/Modals/ModalWrapper';
import TemplateList from './TemplateList';
import TemplateWabaPreview from './TemplateWabaPreview';
import TemplateParams from './TemplateParams';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/arrow-back.svg';


type WabaTemplateMessageProps = {
  setBroadcastMessage: React.Dispatch<React.SetStateAction<BroadcastMessage | undefined>>;
  broadcastItemMessage?: IBroadcastMessage;
  selectedSource?: SourceType | null;
};

function WabaTemplateMessage({ setBroadcastMessage, broadcastItemMessage, selectedSource }: WabaTemplateMessageProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const templates = useSelector(broadcastsWabaTemplatesSelector);
  const [wabaTemplateState, updateWabaTemplateState] = useReducer(wabaTemplateMessageReducer, initialState);
  const [openSelector, setOpenSelector] = useState(false);

  useEffect(() => {
    if (selectedSource) {
      dispatch(getBroadcastWabaTemplates({ companyId, connectionId: selectedSource.id }));
    }
  }, [selectedSource, broadcastItemMessage]);

  useEffect(() => {
    if (broadcastItemMessage && templates.length) {
      const template = templates?.find((t) => t.name === broadcastItemMessage?.wabaTemplate?.templateName);
      if (template && broadcastItemMessage.wabaTemplate) {
        let updated: WabaTemplateParamsUpdate | undefined;
        if (hasWabaTemplateParams(template)) {
          updated = {} as WabaTemplateParamsUpdate
          if (broadcastItemMessage.wabaTemplate.bodyParams) {
            updated.body = broadcastItemMessage.wabaTemplate.bodyParams.map((p) => ({ value: p }));
          }
          if (broadcastItemMessage.wabaTemplate.buttonsParams) {
            updated.buttons = broadcastItemMessage.wabaTemplate.buttonsParams.map((p) => ({ value: p }));
          }
          if (broadcastItemMessage.wabaTemplate.headerParams) {
            updated.header = broadcastItemMessage.wabaTemplate.headerParams
              .filter(p => p.text !== undefined).map((p) => ({ value: p.text  || '' }));
            updated.attachment = broadcastItemMessage.wabaTemplate.headerParams?.find(p => p.url)?.url;
          }
          if (broadcastItemMessage.wabaTemplate.carouselMediaCardParams) {
            updated.carousel = broadcastItemMessage.wabaTemplate.carouselMediaCardParams.map((card) => ({
              attachment: card.headerUrl,
              ...(card.bodyParams && { body: card.bodyParams.map((p) => ({ value: p })) }),
              ...(card.buttonParams && { buttons: card.buttonParams.map((p) => ({ value: p })) })
            }));
          }
        }
        updateWabaTemplateState({ type: 'preselect', payload: { template, values: updated } });
      }
    }
  }, [broadcastItemMessage, templates]);

  useEffect(() => {
    if (wabaTemplateState.stage === 'fullfilled' && wabaTemplateState.message) {
      setBroadcastMessage({ message_type: 'waba_template', waba_template: wabaTemplateState.message });
      setOpenSelector(false);
    }
  }, [wabaTemplateState.stage]);
  
  const openHandler = () => setOpenSelector(true);

  const changeHandler = () => {
    if (wabaTemplateState.message) {
      updateWabaTemplateState({ type: 'fullfill', payload: undefined });
    } else {
      updateWabaTemplateState({ type: 'form', payload: undefined });
    }
  };

  const reselectHandler = () => {
    updateWabaTemplateState({ type: 'reselect', payload: undefined });
    setBroadcastMessage(undefined);
    setOpenSelector(true);
  };

  const backHandler = () => {
    updateWabaTemplateState({ type: 'reselect', payload: undefined });
  };

  const submitHandler = async (data: WabaTemplateParamsUpdate) => {
    updateWabaTemplateState({ type: 'prepare', payload: data });
  };

  return (
    <div className='wabaTemplateMessage'>
      {openSelector &&
        <wabaTemplateContext.Provider value={{ wabaTemplateState, updateWabaTemplateState }} >
          <ModalWrapper setIsOpen={setOpenSelector} background='light' minWidth={false} closeOutside={false}>
            <div className="wabaTemplateMessage__selector">
              {wabaTemplateState.stage === 'select' && <TemplateList templates={templates} />}
              {wabaTemplateState.stage === 'form' && 
                <div className='wabaTemplateMessage__form'>
                  <h4>{t('broadcasts.form.params_template')}</h4>
                  {wabaTemplateState.params &&
                    <TemplateParams params={wabaTemplateState.params} onSave={submitHandler} />}
                  <Button type='button' color='white' textType="small" text={t('back')} image={<ArrowBackIcon />}
                    onClick={backHandler} />
                </div>
              }
              {wabaTemplateState.components && (
                <div className='wabaTemplateMessage__preview'>
                  <h4>{t('broadcasts.form.preview_template')}</h4>
                  <TemplateWabaPreview components={wabaTemplateState.components} />
                  {wabaTemplateState.stage !== 'form' &&
                    <Button type='button' color={wabaTemplateState.message ? 'orange' : 'white'}
                      textType="small" onClick={changeHandler}
                      text={wabaTemplateState.message 
                        ? t('broadcasts.form.add_template') : t('broadcasts.form.open_template') } />}
                </div>
              )}
            </div>
          </ModalWrapper>
        </wabaTemplateContext.Provider>
      }
      {wabaTemplateState.stage === 'fullfilled'
        ? <div className='wabaTemplateMessage__message'>
            <div className='wabaTemplateMessage__message_preview'>
              <h3>{t('broadcasts.form.preview_template')}</h3>
              {wabaTemplateState.components && <TemplateWabaPreview components={wabaTemplateState.components} />}
            </div>
            <Button type='button' color='white' textType='regular' text={t('broadcasts.form.change_template')}
              onClick={reselectHandler} image={<ArrowBackIcon />} />
          </div>
        : <div className='wabaTemplateMessage__button'>
            <Button type='button' color='white' textType='regular' text={t('broadcasts.form.choose_template')}
              onClick={openHandler} image={<PlusIcon />} />
          </div>
      }
    </div>
  );
}

export default WabaTemplateMessage;
