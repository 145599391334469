import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SubscriptionInvoice, checkItemSubscription } from '../../SubscriptionsTypes';
import { SubscriptionsSelector, InvoicesSelector } from '../../SubscriptionsSelector';
import UserSubscriptionInvoice from '../../models/UserSubscriptionInvoice';
import Invoice from '../Invoice';
import './InvoicesList.scss';


const InvoicesList = () => {
  const { t, i18n } = useTranslation();
  const subscriptions = useSelector(SubscriptionsSelector);
  const invoices = useSelector(InvoicesSelector);

  const getUserInvoice = (invoice: SubscriptionInvoice) => {
    const invoiceSubsIds: number[] = [];
    invoice?.summary.items.forEach((item) => {
      if (checkItemSubscription(item)) invoiceSubsIds.push(item.subscriptionId);
    });
    const invoiceSubscriptions = subscriptions.filter((item) => invoiceSubsIds?.includes(item.id));
    if (invoice) {
      return new UserSubscriptionInvoice(invoiceSubscriptions, invoice, t, i18n.language);
    }
    return undefined;
  };

  return (
    <>
      {subscriptions.length > 0 && invoices && invoices.map((invoice) =>
        <Invoice key={invoice.id} invoiceItem={getUserInvoice(invoice)} />
      )}
    </>
  );
};

export default InvoicesList;
