import { ReactElement } from 'react';
import './TemplateWabaButtons.scss';
import { WabaTemplateMessageButtons } from '../../data';
import { ReactComponent as PhoneIcon } from '../../../../../../assets/phone-outgoing.svg';
import { ReactComponent as NewWindowIcon } from '../../../../../../assets/new-window.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/copy.svg';


type WabaTemplateButtonConfig = {
  icon?: ReactElement;
};

export const configWabaTemplateButton: Record<string, WabaTemplateButtonConfig> = {
  quick_reply: {},
  phone_number: { icon: <PhoneIcon /> },
  url: { icon: <NewWindowIcon /> },
  otp: { icon: <CopyIcon /> },
};

type TemplateWabaButtonsProps = {
  buttons: WabaTemplateMessageButtons;
};

const TemplateWabaButtons = ({ buttons }: TemplateWabaButtonsProps) => {
  return (
    <div className="templateWabaButtons">
      {buttons.map((button, i) => (
        <button key={button.type + String(i)}>
          {configWabaTemplateButton[button.type] && configWabaTemplateButton[button.type].icon}
          <p className="smallText middle">{button.text}</p>
        </button>
      ))}
    </div>
  );
};

export default TemplateWabaButtons;
