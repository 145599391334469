import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BroadcastStatusType } from '../../BroadcastsTypes';
import { broadcastStatusAlias } from '../../utils';
import Button from '../../../../components/Button/Button';
import { ReactComponent as InfoIcon } from '../../../../assets/info.svg';
import { ReactComponent as StopIcon } from '../../../../assets/prohibit.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/file-arrow-down.svg';
import './ItemStatus.scss';


type ItemStatusProps = {
  broadcastStatus: BroadcastStatusType;
  onExport: () => void;
  onStop: () => void;
  message?: string;
};

function ItemStatus({ broadcastStatus, onExport, onStop, message }: ItemStatusProps) {
  const { t } = useTranslation();

  const status = broadcastStatusAlias[broadcastStatus];

  const getMessage = () => {
    if (message) return message;
    return t('error.unknown');
  };

  const onDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onExport();
  };

  const onStopHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onStop();
  };

  return (
    <div className='itemStatus'>
      <div className={`itemStatus__content ${status}`}>
        {t(`broadcasts.status.${status}`)}
        {status === 'error' &&
          <div className='itemStatus__info'>
            <InfoIcon />
            <div className='itemStatus__info_message'>{getMessage()}</div>
          </div>}
        {status === 'deliver'
            && <button type='button' onClick={onStopHandler}><StopIcon />{t('stop')}</button>}
      </div>
        <Button type='button' color='white' textType='small' image={<DownloadIcon />} onClick={onDownload} />
    </div>
  );
}

export default memo(ItemStatus);
