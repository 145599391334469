import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './ModalWrapper.scss';
import { OverlayBackgroundType } from '../types';
import { ReactComponent as CloseIcon } from "../../../assets/cross.svg";


type ModalWrapperProps = {
  children: ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
  closeOutside?: boolean;
  minWidth?: boolean;
};

function ModalWrapper({ children, setIsOpen, background, closeOutside = true, minWidth = true }: ModalWrapperProps) {

  const onOutsideClick = () => {
    if (closeOutside && setIsOpen) setIsOpen(false);
  };

  const onCloseDefault = () => {
    if (setIsOpen) setIsOpen(false);
  };

  return ReactDOM.createPortal(
    <div className="modalWrapper__container">
      <div className={`modalWrapper__wrapper ${background}`} onClick={onOutsideClick}>{}</div>
      {setIsOpen
        ? (
          <div className={`modalWrapper ${minWidth && 'minWidth'}`}>
            <CloseIcon onClick={onCloseDefault} />
            {children }
          </div>
        ) : (<>
          {children}
        </>)
      }
    </div>, document.body
  );
}

export default ModalWrapper;
