import React, { useEffect, useRef } from 'react';
import './CreateAndEditTag.scss';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { clearTag } from '../TagsSlice';
import { useOutsideClick } from '../../../../components/utils/hooks';
import { tagSelector } from '../TagsSelector';
import { tagsColors } from '../utils';
import { useSubmitTag } from "../api";
import Button from '../../../../components/Button';
import Textarea from "../../Textarea/Textarea";
import Input from "../../../../components/Input";
import loader from "../../../../assets/grid.svg";


type Props = {
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateAndEditTag: React.FC<Props> = ({ setIsDisable, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const editTag = useSelector(tagSelector);
  const onSubmitTag = useSubmitTag();
  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(15, t('error.form.max_length', { limit: 15 })),
    description: yup.string().max(256, t('error.form.max_length', { limit: 256 })),
    backgroundColor: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      backgroundColor: '#FFFFFF',
    },
  });

  const closeModalWindows = () => {
    setIsDisable(false);
    setIsEdit(false);
    dispatch(clearTag());
  };

  useOutsideClick(wrapperRef, closeModalWindows, triggerButtonRef);

  useEffect(() => {
    if (isEdit && editTag) {
      methods.reset({
        name: editTag.name,
        description: editTag.description,
        backgroundColor: editTag.backgroundColor,
      });
    }
  }, [editTag]);

  const onSubmit = (data: { name: string; description: string; backgroundColor: string }) => {
    onSubmitTag(data, isEdit, editTag).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setIsDisable(false);
        setIsEdit(false);
        dispatch(clearTag());
      }
    });
  };

  if (isEdit && !editTag) {
    return (
      <div className="createAndEditTag" ref={wrapperRef}>
        <div className="loaderContainer">
          <img src={loader} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="createAndEditTag" ref={wrapperRef}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="createAndEditTag_header">
            <h3>{isEdit ? t('settings.tags.edit.title') : t('settings.tags.create.title')}</h3>
            <CloseIcon onClick={closeModalWindows} ref={triggerButtonRef} />
          </div>
          <Input
            name="name"
            label={t('settings.tags.create.name')}
            rightText={t('error.char_limit', { limit: 15 })}
          />
          <Textarea
            name="description"
            label={t('settings.tags.create.description')}
            rightText={t('error.char_limit', { limit: 256 })}
          />
          <div className="createAndEditTag__colors">
            <p className="smallText middle">{t('settings.tags.create.color')}</p>
            <div className="colorItems">
              {tagsColors.map((item) => (
                <div
                  key={item.id}
                  className="colorItems_item"
                  style={
                    item.color === '#FFFFFF'
                      ? { background: item.color, border: '1px solid #EBEBEB' }
                      : { background: item.color }
                  }
                  onClick={() => methods.setValue('backgroundColor', item.color, { shouldDirty: true })}
                >
                  {methods.watch('backgroundColor') === item.color ? <CheckmarkIcon className="dawIcon" /> : ''}
                </div>
              ))}
            </div>
          </div>
          <div className="createAndEditTag__footer">
            <p className="regularText middle">{t('settings.tags.create.preview')}</p>
            <div className="createAndEditTag__footer_preview" style={{ background: methods.watch('backgroundColor') }}>
              <span className="smallText">
                {methods.watch('name').length > 0 ? methods.watch('name') : t('settings.tags.create.name_tag')}
              </span>
            </div>
          </div>
          <div className="createAndEditTag__buttons">
            <Button
              text={isEdit ? t('save') : t('settings.tags.create_button')}
              textType="small" color="orange"
              disabled={!methods.formState.isDirty}
              type="submit"
            />
            <Button
              id="cancel" text={t('cancel')}
              color="white" textType="small"
              onClick={closeModalWindows}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateAndEditTag;
