import React from 'react';
import ReactDOM from 'react-dom';
import './MobileModal.scss';
import { OverlayBackgroundType } from '../types';


type MobileInfoProps = {
  children: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

function MobileModal({ children, background='none', setIsOpen }: MobileInfoProps) {

  const onCloseClick = () => setIsOpen(false);

  return ReactDOM.createPortal(
    <div className='mobileModal'>
      {children}
      <div className={`mobileModal__wrapper ${background}`} onClick={onCloseClick}>{}</div>
    </div>, document.body
  );
}

export default MobileModal;
