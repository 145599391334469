import React, { ReactElement } from 'react';
import { ReactComponent as Loader } from '../../assets/small-loader.svg';
import './Button.scss';


type ColorType = 'white' | 'orange';
type TextType = 'regular' | 'small';

type Props = {
  text?: string;
  image?: ReactElement;
  color: ColorType;
  textType: TextType;
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({ text, image, color, textType, type = 'button', loading, ...props }) => {
  return (
    <button
      className={`GlobalButton ${props.disabled ? 'disabled' : ''} ${color} ${textType} ${
        text && image ? 'isImage' : ''
      }`}
      type={type}
      {...props}
    >
      {loading ? <Loader className='loader' /> : image}
      {text}
    </button>
  );
};

export default Button;
