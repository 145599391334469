import { useEffect, useState } from 'react';
import { RecalculatedContactsType } from '../BroadcastsForm/ContactsForm/utils/types';


const initContacts: RecalculatedContactsType = { all: [], recipients: [], errors: 0, duplicates: 0 };

export function useContactList(initRecipients?: string[]) {
  const [contacts, setContacts] = useState<RecalculatedContactsType>(initContacts);
  const [isChangedContacts, setIsChangedContacts] = useState<boolean>(false);

  useEffect(() => {
    if (initRecipients) {
      setContacts({ all: [...initRecipients], recipients: [...initRecipients], errors: 0, duplicates: 0 });
    } else {
      setContacts(initContacts);
    }
  }, [initRecipients]);

  useEffect(() => {
    let isChanged: boolean;
    if (initRecipients) {
      isChanged = initRecipients.length !== contacts.recipients.length;
    } else {
      isChanged = contacts.recipients.length > 0;
    }
    setIsChangedContacts(isChanged);
  }, [contacts]);

  return { contacts, setContacts, isChangedContacts };
}
