import './WabaTemplateButton.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReturn, UseFormReturn, useWatch } from 'react-hook-form';
import { useVariable, wabaTemplateButtonTextLimit, wabaTemplateCategoryButtons } from '../../../../utils/waba';
import { WabaTemplateCategory } from '../../../../templatesTypes';
import { WabaTemplateAuthData } from '../../../WabaTemplateProvider/utils';
import Dropdown from '../../../../../Settings/Dropdown/Dropdown';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import PhoneField from '../../../../../../components/PhoneInput/PhoneField';
import Counter from '../Counter';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


type WabaTemplateButtonProps = {
  category: WabaTemplateCategory;
  id: number;
} & UseFormReturn<WabaTemplateAuthData> & UseFieldArrayReturn<WabaTemplateAuthData>;

type Named = {
  id: string | number;
  name: string;
};

const VARIABLES_MAX = 1;

function WabaTemplateButton({ id, category, ...props }: WabaTemplateButtonProps) {
  const { t } = useTranslation();
  const [buttons, setButtons] = useState<Named[]>([]);

  useEffect(() => {
    setButtons(wabaTemplateCategoryButtons[category].map((button) => (
      { id: button, name: t(`templates.waba_list.form.buttons.types.${button}`) })));
  }, []);

  const text = useWatch({ name: `buttons.[${id}].text` });
  const type = useWatch({ name: `buttons.[${id}].type` });
  const url = useWatch({ name: `buttons.[${id}].url` });
  const autofillText = useWatch({ name: `buttons.[${id}].autofill_text` });
  const packageName = useWatch({ name: `buttons.[${id}].package_name` });
  const signature = useWatch({ name: `buttons.[${id}].signature_hash` });

  const { variables, addVariable } = useVariable(VARIABLES_MAX, url);

  useEffect(() => {
    if (variables > VARIABLES_MAX) props.setError(
      `buttons.${id}.url`, { type: 'custom', message: t('templates.waba_list.form.variable_limit') });
  }, [variables]);

  const onVariableClick = () => {
    if (!addVariable()) return;
    props.setValue(`buttons.${id}.url`, `${url}{{1}}`, { shouldDirty: true, shouldValidate: true });
  };

  const onDelete = () => {
    props.remove(id);
  };

  return (
    <div className='wabaTemplateButton'>
      <div className='wabaTemplateButton__header smallText'>
        {buttons.length > 0 &&
          <Dropdown name={`buttons.[${id}].type`} items={buttons} label='' labelKey='name' valueKey='id' />
        }
        {type === 'URL' &&
          <Button type='button' color='white' textType='small' text={t('templates.waba_list.form.variable')}
            image={<PlusIcon />} onClick={onVariableClick} disabled={variables >= VARIABLES_MAX} />
        }
        <Button id='delete' type='button' color='white' textType='small' text='' image={<DeleteIcon />}
          onClick={onDelete} />
      </div>
      {type !== 'ONE_TAP' && <>
        <Input name={`buttons.[${id}].text`} autoComplete='off' maxLength={wabaTemplateButtonTextLimit[category].text}
          placeholder={t('templates.waba_list.form.buttons.placeholder')} />
        <Counter counter={text?.length || 0} maximum={wabaTemplateButtonTextLimit[category].text}
          text='templates.waba_list.form.character.character' />
      </>}
      {type === 'URL' &&
        <Input name={`buttons.[${id}].url`} autoComplete='off'
          placeholder={t('templates.waba_list.form.buttons.types.URL')} />
      }
      {type === 'PHONE_NUMBER' &&
        <PhoneField name={`buttons.[${id}].phone_number`} />
      }
      {type === 'ONE_TAP' && <>
        <Input name={`buttons.[${id}].autofill_text`} autoComplete='off'
          maxLength={wabaTemplateButtonTextLimit[category].autofill_text}
          placeholder={t('templates.waba_list.form.buttons.autofill_text')} />
        <Counter counter={autofillText?.length || 0} maximum={wabaTemplateButtonTextLimit[category].autofill_text}
          text='templates.waba_list.form.character.character' />
        <Input name={`buttons.[${id}].text`} autoComplete='off'
          maxLength={wabaTemplateButtonTextLimit[category].copy_text}
          placeholder={t('templates.waba_list.form.buttons.copy_text')} />
        <Counter counter={text?.length || 0} maximum={wabaTemplateButtonTextLimit[category].copy_text}
          text='templates.waba_list.form.character.character' />
        <Input name={`buttons.[${id}].package_name`} autoComplete='off'
          maxLength={wabaTemplateButtonTextLimit[category].package_name}
          placeholder={t('templates.waba_list.form.buttons.package_name')} />
        <Counter counter={packageName?.length || 0}
          maximum={wabaTemplateButtonTextLimit[category].package_name}
          text='templates.waba_list.form.character.character' />
        <Input name={`buttons.[${id}].signature_hash`} autoComplete='off'
          maxLength={wabaTemplateButtonTextLimit[category].signature_hash}
          placeholder={t('templates.waba_list.form.buttons.signature_hash')} />
        <Counter counter={signature?.length || 0} maximum={wabaTemplateButtonTextLimit[category].signature_hash}
          text='templates.waba_list.form.character.character' />
      </>}
    </div>
  );
}

export default WabaTemplateButton;
