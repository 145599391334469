import React, { useState } from 'react';
import './ContactItem.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { setCurrentContact } from '../../contactsSlice/contactsSlice';
import { ContactType } from '../../ContactsAPI';
import { ChatItemContact } from '../ChatItem/ChatItemContact';
import { allConnectionsForChatSelector } from '../../../Chat/companiesSelector';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow-down.svg';
import Avatar from "../../../../components/Avatar";


type PropsContactsList = {
  contact: ContactType;
  companyId: number;
};

export const ContactItem: React.FC<PropsContactsList> = React.memo(({ contact, companyId }) => {
  const { t } = useTranslation();
  const [isVisibleAllChats, setIsVisibleAllChats] = useState(false);
  const connections = useSelector(allConnectionsForChatSelector);
  const chatsContact = contact.chats.filter((chat, index) => isVisibleAllChats ? true : index === 0)

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const onChangeHandler = (c: ContactType) => {
    dispatch(setCurrentContact(contact));
    dispatch(push(`${pathname}/${c.id}`));
  };

  const activeSetToggle = () => {
    setIsVisibleAllChats(!isVisibleAllChats);
  };

  if (!contact) {
    return null;
  }

  return (
    <div className='contactItem' key={contact.id}>
      <div className={isVisibleAllChats ? 'contactItem__show active' : 'contactItem__show'}
        onClick={() => onChangeHandler(contact)}>
        <div className='contactPhoto'>
         <Avatar image={contact.avatarUrl} name={contact.name} />
        </div>
        <div className='contactName'>
          <p className='regularText middle'>{contact.name}</p>
        </div>
        <div className='contactEmail'>
          {contact.emails !== null && contact.emails.map((c) =>
            <p key={c.id} className='regularText'>{c.value}</p>)}
        </div>
        <div className="contactPhone">
          {isVisibleAllChats && contact.phones && contact.phones.map((p) => (
            <p key={p.id} className="monospaceRegText">
              {parsePhoneNumberFromString(p.value)?.formatInternational()}
            </p>
          ))}
          {!isVisibleAllChats && contact.phones && contact.phones.map((p, index) => index < 3 && (
            <p key={p.id} className="monospaceRegText">
              {parsePhoneNumberFromString(p.value)?.formatInternational()}
            </p>
            )
          )}
        </div>
        <div className='contactTags'>
          <div className='contactTags_list'>
            {contact?.tags?.length > 0 && contact.tags.map((i) => (
              <div key={i.id} className='contactTags_list_item' style={{ backgroundColor: `${i.backgroundColor}` }}>
                <p className='smallText'>{i.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='contactChat'>
          {chatsContact.map(chat =>
            <ChatItemContact
              connections={connections}
              companyId={companyId} contactId={contact.id} key={chat.chatId}
              item={chat} />
          )}
        </div>
      </div>
      {contact.chats.length > 1 && (
        <div className='contactItem__toggle'>
          <div onClick={activeSetToggle} className='contactItem__toggle__item'>
            <ArrowIcon className={`arrow ${isVisibleAllChats ? 'open' : ''}`} />
            <p>{isVisibleAllChats ? t('contacts.contactsList.rollUp') : t('contacts.contactsList.expand')}</p>
          </div>
        </div>
      )}
    </div>
  );
});
