import './Subscriptions.scss';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import subscriptionsLogo from '../../../assets/subscriptions.png';
import Button from '../../../components/Button/Button';
import TariffButton from "./components/TariffButton";


const SubscriptionsPlug = () => {
  const { t } = useTranslation();
  const url = useRouteMatch('/companies/:companyId');
  const history = useHistory();
  const onClick = () => {
    history.push(`${url?.url}/settings`);
  };
  return (
    <div className='subscriptions'>
      <div className='SubscriptionsPlug'>
        <img src={subscriptionsLogo} alt='subscriptions logo'/>
        <h1>{t('subscriptions.title')}</h1>
        <p className="SubscriptionsPlug__description">{t('subscriptions.description')}</p>
        <TariffButton />
        <Button text={t('subscriptions.connections')} textType='regular'
          color='orange' onClick={onClick} />
      </div>
    </div>
  );
};

export default SubscriptionsPlug;
