import './TemplateWabaHeader.scss';
import { WabaTemplateMessageHeader } from '../../data';
import FilePreview from '../../../../../../components/FilePreview';


type TemplateWabaHeaderProps = {
  header: WabaTemplateMessageHeader;
};

const TemplateWabaHeader = ({ header }: TemplateWabaHeaderProps) => {

  return (
    <div className="templateWabaHeader">
      {header.format === 'text'
        ? (<>
          {header.text && <p className="smallText semibold">{header.text}</p>}
        </>)
        : (
          <FilePreview format={header.format} file={header.file} />
        )
      }
    </div>
  );
};

export default TemplateWabaHeader;
