import React, { useContext } from 'react';
import './RequisiteItem.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { PaymentRequisite } from '../../SubscriptionsTypes';
import { subRequisiteItemContext } from '../RequisitesView/utils';
import { useMobile } from "../../../../../components/utils/hooks";
import Button from '../../../../../components/Button';


type Props = {
  requisite: PaymentRequisite;
};

const RequisiteItem = ({ requisite }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { onDelete, onEdit } = useContext(subRequisiteItemContext);

  const onEditHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    e.stopPropagation();
    if (onEdit) onEdit(requisite);
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(requisite);
  };

  return (
    <div className="subRequisiteItem">
      <div className="regularText" data-title={t('subscriptions.requisites.req_list.name')}>
        {requisite.name}
      </div>
      <div className="monospaceRegText" data-title={t('subscriptions.requisites.req_list.tin')}>
        {requisite.tin}
      </div>
      <div className={`subRequisiteItem__status ${requisite.approvalStatus.toLowerCase()}`}
        data-title={t('subscriptions.requisites.req_list.status')}>
        <p className={`regularText ${isMobile ? 'semibold' : 'middle'}`}>
          {t(`subscriptions.requisites.status.${requisite.approvalStatus.toLowerCase()}`)}
        </p>
        <WrapperPermission permission="subscriptionsEdit">
          <div className="subRequisiteItem__status_buttons">
            <Button color="white" textType="small" image={<EditIcon />} 
                    onClick={(e) => onEditHandler(e)} id='edit' />
            <Button color="white" textType="small" image={<DeleteIcon />}
                    onClick={(e) => onDeleteHandler(e)} id='delete' />
          </div>
        </WrapperPermission>
      </div>
    </div>
  );
};

export default RequisiteItem;
