export const locales = [
  { id: 'af', name: 'Afrikaans' },
  { id: 'sq', name: 'Albanian' },
  { id: 'ar', name: 'Arabian' },
  { id: 'az', name: 'Azerbaijanian' },
  { id: 'bn', name: 'Bengali' },
  { id: 'bg', name: 'Bulgarian' },
  { id: 'ca', name: 'Catalan' },
  { id: 'zh_CN', name: 'Chinese (China)' },
  { id: 'zh_HK', name: 'Chinese (Hong Kong)' },
  { id: 'zh_TW', name: 'Chinese (Taiwan)' },
  { id: 'hr', name: 'Croatian' },
  { id: 'cs', name: 'Czech' },
  { id: 'da', name: 'Danish' },
  { id: 'nl', name: 'Dutch' },
  { id: 'en', name: 'English' },
  { id: 'en_GB', name: 'English (UK)' },
  { id: 'en_US', name: 'English (USA)' },
  { id: 'et', name: 'Estonian' },
  { id: 'fil', name: 'Filipino' },
  { id: 'fi', name: 'Finnish' },
  { id: 'fr', name: 'French' },
  { id: 'de', name: 'German' },
  { id: 'el', name: 'Greek' },
  { id: 'gu', name: 'Gujarati' },
  { id: 'he', name: 'Hebrew' },
  { id: 'hi', name: 'Hindi' },
  { id: 'hu', name: 'Hungarian' },
  { id: 'id', name: 'Indonesian' },
  { id: 'ga', name: 'Irish' },
  { id: 'it', name: 'Italian' },
  { id: 'ja', name: 'Japanese' },
  { id: 'kn', name: 'Kannada' },
  { id: 'kk', name: 'Kazakh' },
  { id: 'ko', name: 'Korean' },
  { id: 'lo', name: 'Lao' },
  { id: 'lv', name: 'Latvian' },
  { id: 'lt', name: 'Lithuanian' },
  { id: 'mk', name: 'Macedonian' },
  { id: 'ms', name: 'Malay' },
  { id: 'mr', name: 'Marathi' },
  { id: 'nb', name: 'Norwegian' },
  { id: 'fa', name: 'Persian' },
  { id: 'pl', name: 'Polish' },
  { id: 'pt_BR', name: 'Portuguese (Brazil)' },
  { id: 'pt_PT', name: 'Portuguese (Portugal)' },
  { id: 'pa', name: 'Punjabi' },
  { id: 'ro', name: 'Romanian' },
  { id: 'ru', name: 'Russian' },
  { id: 'sr', name: 'Serbian' },
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  { id: 'es', name: 'Spanish' },
  { id: 'es_AR', name: 'Spanish (Argentina)' },
  { id: 'es_ES', name: 'Spanish (Spain)' },
  { id: 'es_MX', name: 'Spanish (Mexico)' },
  { id: 'sw', name: 'Swahili' },
  { id: 'sv', name: 'Swedish' },
  { id: 'ta', name: 'Tamil' },
  { id: 'te', name: 'Telugu' },
  { id: 'th', name: 'Thai' },
  { id: 'tr', name: 'Turkish' },
  { id: 'uk', name: 'Ukrainian' },
  { id: 'ur', name: 'Urdu' },
  { id: 'uz', name: 'Uzbek' },
  { id: 'vi', name: 'Vietnamese' },
  { id: 'zu', name: 'Zulu' },
];
