import { FileMessage } from '../../../../../api/messages/types';
import './MessageDisplayImage.scss';


type MessageDisplayImageProps = {
  message: FileMessage;
};

function MessageDisplayImage({ message }: MessageDisplayImageProps) {
  return (
    <div className='messageDisplayImage'>
      <p className='regularText'>{message.caption}</p>
      <img alt='preview' src={message.url} />
    </div>
  );
}

export default MessageDisplayImage;
