import './FileAttach.scss';
import Button from '../Button';
import { ReactComponent as AttachmentIcon } from '../../assets/attach.svg';


type FileAttachProps = {
  caption: string;
  label: string;
  extensions: string;
  maxSizeMB: number;
  setAttachment: (file: File) => void;
  onError: () => void;
};

function FileAttach({ caption, label, extensions, maxSizeMB, setAttachment, onError }: FileAttachProps) {

  const onLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxSizeMB && e.target.files && e.target.files[0].size > maxSizeMB * 1024 * 1024) {
      onError();
    } else if (e.target.files) {
      setAttachment(e.target.files[0]);
    }
  };

  return (
    <div className="fileAttach">
      <Button type='button' color="white" textType="small" text={caption}
        image={<AttachmentIcon />} />
      <input type="file" accept={extensions} onChange={onLoadFile} />
      <p className="smallText">{label}</p>
    </div>
  );
}

export default FileAttach;
