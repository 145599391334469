import { FileMessage } from '../../../../../api/messages/types';
import './MessageDisplayFile.scss';


type MessageDisplayFileProps = {
  message: FileMessage;
};

function MessageDisplayFile({ message }: MessageDisplayFileProps) {
  return (
    <div className='messageDisplayFile'>
      <p className='regularText'>{message.caption}</p>
      <a target='_blank' href={message.url} rel="noreferrer">{message.name}</a>
    </div>
  );
}

export default MessageDisplayFile;
