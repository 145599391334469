import './MessageDisplayWaba.scss';
import { WabaTemplateMessage } from '../../../../../api/messages/types';
import LinkifyText from '../../../../../components/LinkifyText';
import WabaButtons from './WabaButtons';
import WabaCarousel from './WabaCarousel';
import WabaHeader from './WabaHeader';


type MessageDisplayWabaProps = {
  message: WabaTemplateMessage
};

function MessageDisplayWaba({ message }: MessageDisplayWabaProps) {
  return (
    <div className='messageDisplayWaba'>
      {message.carousel && message.carousel.length > 0
        ? <>
          <div className="messageWabaTemplate__text">
            <LinkifyText className="regularText" text={message.text} />
          </div>
          <WabaCarousel carousel={message.carousel} />
        </> : <>
          {message.mediaHeader && <WabaHeader header={message.mediaHeader} />}
          <div className="messageWabaTemplate__text">
            <LinkifyText className="regularText" text={message.text} />
          </div>
          {message.buttons && message.buttons.length > 0 &&
            <div className="messageWabaTemplate__buttons">
              <WabaButtons buttons={message.buttons} />
            </div>
          }
        </>
      }
    </div>
  );
}

export default MessageDisplayWaba;
