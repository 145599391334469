import { useState } from 'react';
import './PromoCode.scss';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMobile } from '../../../../../../components/utils/hooks';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';
import MobileInfo from '../../../../../../components/Modals/MobileInfo';


type Props = {
  setPromo: (promo: string) => void;
  showInfo: boolean;
};

function PromoCode({ setPromo, showInfo }: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const schema = yup.object().shape({
    promo_code: yup.string().required(t('error.form.empty_field')),
  });

  const methods = useForm({
     mode: 'onSubmit', reValidateMode: 'onSubmit', defaultValues: { promo_code: '' }, resolver: yupResolver(schema) });

  const onSubmit = (data: { promo_code: string; }) => {
    setPromo(data.promo_code);
  };

  const onInfoClick = () => {
    if (isMobile) setInfoOpen(!infoOpen);
  };

  return (
    <div className="promoCode">
      {infoOpen && showInfo && <MobileInfo setIsOpen={setInfoOpen} message={t('subscriptions.invoice.promo_info')} />}
      <FormProvider {...methods}>
        <div className="promoCode__field">
          <div className="promoCode__field_lable">
            <p className='regularText bold'>{t('subscriptions.invoice.promo_code')}</p>
            {showInfo && <>
              <span className="info__icon" onClick={onInfoClick}><InfoIcon /></span>
              <div className="info__message">{t('subscriptions.invoice.promo_info')}</div>
            </>}
          </div>
          <Input name='promo_code' />
        </div>
        <Button type='submit' text={t('apply')} color='white' textType='regular'
          onClick={methods.handleSubmit(onSubmit)} />
      </FormProvider>
    </div>
  );
};

export default PromoCode;
