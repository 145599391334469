import { useTranslation } from 'react-i18next';
import { formatNumberLocale } from '../../../../utils/utils';
import './ItemRecievers.scss';


type ItemRecieversProps = {
  recievers: number;
};

function ItemRecievers({ recievers }: ItemRecieversProps) {
  const { i18n } = useTranslation();
  
  return (
    <div className='itemRecievers'>{formatNumberLocale(recievers, 0, i18n.language)}</div>
  );
}

export default ItemRecievers;
