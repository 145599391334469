import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross.svg';
import Button from '../../../../../components/Button';
import './PaidWarn.scss';


type Props = {
  handleConfirm: () => void;
};

export function PaidWarn({ handleConfirm }: Props) {
  const { t } = useTranslation();

  return (
    <div className="paidWarn__wrapper">
      <div className="paidWarn">
        <div className="paidWarn__close">
          <CloseIcon onClick={handleConfirm} />
        </div>
        <h4>{t('subscriptions.paid_warn.head')}</h4>
        <div className="paidWarn__content">
          <p>{t('subscriptions.paid_warn.line_1')}</p>
          <p>{t('subscriptions.paid_warn.line_2')}</p>
        </div>
        <Button color='orange' textType='regular' text={t('confirm')} onClick={handleConfirm} />
      </div>
    </div>
  );
}
