import React from 'react';
import './TagItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from "../TagsTypes";
import { getItemTag } from '../TagsSlice';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import Button from '../../../../components/Button';


type Props = {
  tag: Tag;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagItem: React.FC<Props> = ({ tag, setIsEdit, setIsDisable }) => {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const onEdit = () => {
    dispatch(getItemTag({ companyId: currentCompany.id, tagId: tag.id }));
    setIsDisable(true);
    setIsEdit(true);
  };

  return (
    <div className="tagItem">
      <div className="tagItem__name">
        <div className="tagItem__name_container" style={{ background: tag.backgroundColor }}>
          <p className='smallText'>{tag.name}</p>
        </div>
      </div>
      <div className="tagItem__description">
        <p className='regularText'>{tag.description}</p>
        <WrapperPermission permission="tagsEdit">
          <Button id='edit' type="button" color="white" textType="small" onClick={onEdit} image={<EditIcon />} />
        </WrapperPermission>
      </div>
    </div>
  );
};

export default TagItem;
