import { useTranslation } from 'react-i18next';
import './MessagePlug.scss';


function MessagePlug() {
  const { t } = useTranslation();

  return (
    <div className='messagePlug'>
      <p className='regularText middle'>{t('broadcasts.form.message_lable')}</p>
      <div className='messagePlug__field'>
        <p className='regularText'>{t('broadcasts.form.message_plug')}</p>
      </div>
    </div>
  );
}

export default MessagePlug;
