import './DialogsInput.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { formatNumberLocale } from '../../../../../../utils/utils';
import { useDialogRecommendation } from '../../../api';
import Input from '../../../../../../components/Input/Input';


function DialogsInput() {
  const { t, i18n } = useTranslation();
  const { getValues } = useFormContext();
  const recommends = useDialogRecommendation();

  const recomendText = Number(getValues('period_months')) === 1
    ? t('subscriptions.invoice.dialogs.recomend_same', { quantity: recommends?.value.recommendedCount })
    : t('subscriptions.invoice.dialogs.recomend_quantity', {
      quantity: formatNumberLocale(
        Number(recommends?.value.recommendedCount) * Number(getValues('period_months')), 0, i18n.language),
      period: getValues('period_months') });

  return (
    <div className='dialogsInput'>
      <h3>{t('subscriptions.invoice.dialogs.caption')}</h3>
      {recommends &&
        <p>
          {`${t('subscriptions.invoice.dialogs.info', {
            dialogs: formatNumberLocale(recommends.value.previousCount, 0, i18n.language) })} ${recomendText}`}
        </p>
      }
      <Input name='dialogs' inputType='number' placeholder={t('subscriptions.invoice.dialogs.placeholder')} min={0} step={100} />
    </div>
  );
}

export default DialogsInput;
