import './Attachment.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { getError } from '../../../../../../components/utils/forms';
import { attachmentConfig } from '../../data';
import Button from '../../../../../../components/Button';
import FilePreview from '../../../../../../components/FilePreview';
import FileAttach from '../../../../../../components/FileAttach';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/trash.svg';


type AttachmentProps = {
  field: string;
  format: string;
  attachment: File | undefined;
  setAttachment: (file: File | undefined) => void;
};

function Attachment({ field, format, attachment, setAttachment }: AttachmentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setValue, formState: { errors } } = useFormContext();
  const isError = getError(errors, field);

  const onError = () => {
    dispatch(addAlertWithCustomText({ message: t('broadcasts.form.fill_template.error_big_file'), type: 'alarm' }));
  };

  const onAttach = (file: File) => {
    setAttachment(file);
    setValue(field, file.name, { shouldDirty: true, shouldValidate: true });
  };

  const onDelete = () => {
    setAttachment(undefined);
    setValue(field, '', { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className={`attachment ${isError && 'error'}`}>
      {attachment
        ? (<>
          <FilePreview format={format} file={attachment} />
          <Button id='delete' type='button' color='white' textType='small' text='' image={<DeleteIcon />}
            onClick={onDelete} />
        </>)
        : (<FileAttach caption={t(`broadcasts.form.fill_template.${format}.caption`)}
              label={t(`broadcasts.form.fill_template.${format}.label`,
                { extensions: attachmentConfig[format].extensions,  size: attachmentConfig[format].sizeMB })}
              extensions={attachmentConfig[format].extensions} maxSizeMB={attachmentConfig[format].sizeMB}
              setAttachment={onAttach} onError={onError} />)
      }
    </div>
  );
}

export default Attachment;
