import { TextMessage } from '../../../../../api/messages/types';
import './MessageDisplayText.scss';


type MessageDisplayTextProps = {
  message: TextMessage;
};

function MessageDisplayText({ message }: MessageDisplayTextProps) {
  return (
    <p className='regularText'>{message.text}</p>
  );
}

export default MessageDisplayText;
