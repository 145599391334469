
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { optionsContext } from '../../../../api';
import { Named, MessagingOption, MessagingPipeline } from '../../../../types';
import { integrationContext } from '../../../../utils';
import { WrapperPermission } from '../../../../../../../utils/WrapperPermission/WrapperPermission';
import Select from '../../../../../../../components/Select';
import './OptionSelect.scss';


type OptionSelectProps = {
  connection: MessagingOption;
};

function OptionSelect({ connection }: OptionSelectProps) {
  const { t } = useTranslation();
  const { area } = useContext(integrationContext);
  const { pipelines, onChange } = useContext(optionsContext);
  
  const onChangePipeline = (item: MessagingPipeline) => {
    if (connection === undefined) return;
    connection.pipeID = item.id;
    onChange(connection);
  };

  const onChangeStatusPipeline = (item: MessagingPipeline) => {
    if (connection === undefined) return;
    connection.statusID = item.id;
    onChange(connection);
  };

  const getStatuses = () => {
    let statuses: Named[] =
      (pipelines)?.find(i => i.id === connection?.pipeID)?.statuses ?? [];
    statuses = [{ id: 0, name: t('settings.integrations.choose_pipeline') }, ...statuses];
    return statuses;
  };

  const pipelineName = () => (pipelines).find(item => item.id === connection?.pipeID)?.name;

  const statusName = () => getStatuses().find(item => item.id === connection?.statusID)?.name;

  return (
    <div className='optionSelect'>
      <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
        <Select labelKey='name' items={pipelines} onChoose={onChangePipeline} value={pipelineName()}
          placeholder={t(`settings.integrations.${area}.pipe_placeholder`)} />
      </WrapperPermission>
      {area === 'amocrm' && <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
        <Select labelKey='name' items={getStatuses()} onChoose={onChangeStatusPipeline} value={statusName()}
        placeholder={t(`settings.integrations.${area}.status_placeholder`)} />
      </WrapperPermission>}
    </div>
  );
}

export default OptionSelect;
