import './Requisites.scss';
import { useTranslation } from "react-i18next";
import { useHasPermission } from "../../../../../../utils/WrapperPermission/WrapperPermission";
import { PaymentRequisite } from '../../../SubscriptionsTypes';
import Dropdown from "../../../../Dropdown/Dropdown";
import DropdownEmptyLink from "../../../../../../components/DropdownEmptyLink/DropdownEmptyLink";


type Props = {
  onAddNewRequisites: () => void;
  requisites: PaymentRequisite[];
};

const Requisites = ({ onAddNewRequisites, requisites }: Props) => {
  const { t } = useTranslation();
  const permissionEdit = useHasPermission('subscriptionsEdit');

  return (
    <div className="requisites">
      {requisites.length > 0 ? (
        <Dropdown name="company_requisite_id" label={t('subscriptions.invoice.requisite_title')}
          items={requisites} labelKey="name" valueKey="id" placeholder={t('select')} />
      ) : (
        <DropdownEmptyLink label={t('subscriptions.invoice.requisite_title')}
          link="settings/subscriptions" options={onAddNewRequisites} visibleButton={permissionEdit}
          linkText={t('subscriptions.requisites.add_requisites')}
          placeholder={t('subscriptions.invoice.requisite_none')} />
      )}
    </div>
  );
};

export default Requisites;
