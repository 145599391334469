import { createContext, Dispatch } from "react";
import {
  WabaTemplate, WabaTemplateButton, WabaTemplateHeaderFormat, WabaTemplateMessageButton
} from "../../../../api/templates/types";


const stages = ['select', 'form', 'fullfilled'] as const;

export type WabaTemplateMessageStage = typeof stages[number];

export type ParamsType = {
  id: number;
  value: string;
  label: string;
};

export type WabaTemplateMessageHeader = {
  format: WabaTemplateHeaderFormat
  file?: File | string;
  text?: string;
};

export type WabaTemplateMessageButtons = Array<{
  type: WabaTemplateMessageButton;
  text: string;
  phoneNumber: string;
  url: string;
}>;

export type WabaTemplateMessageCard = {
  header: WabaTemplateMessageHeader;
  body?: string;
  buttons?: WabaTemplateMessageButtons;
};

export type WabaTemplateMessageComponents = {
  body: string;
  header?: WabaTemplateMessageHeader;
  footer?: string;
  buttons?: WabaTemplateMessageButtons;
  carousel?: WabaTemplateMessageCard[];
};

export type WabaTemplateMessageCardParams = {
  attachment: File | undefined;
  body?: ParamsType[];
  buttons?: ParamsType[];
};

export type WabaTemplateMessageParams = {
  attachment?: File;
  header?: ParamsType[];
  body?: ParamsType[];
  buttons?: ParamsType[];
  carousel?: WabaTemplateMessageCardParams[];
};

export type StringValue = {
  value: string;
};

export type WabaTemplateMessageCardData = {
  attachment: string;
  body?: StringValue[];
  buttons?: StringValue[];
};

export type WabaTemplateParamsData = {
  attachment: string;
  header: StringValue[];
  body: StringValue[];
  buttons: StringValue[];
  carousel?: WabaTemplateMessageCardData[];
};

export type WabaTemplateParamsUpdate = {
  attachment: File | string | undefined;
  header: StringValue[];
  body: StringValue[];
  buttons: StringValue[];
  carousel: Array<{
    attachment: File| string | undefined;
    body?: StringValue[];
    buttons?: StringValue[];
  }>;
};

type WabaTemplateMessageContentHeader = {
  file?: File | string;
  text?: string;
};

type WabaTemplateMessageCarousel = {
  header: File | string;
  body_params?: string[];
  button_params?: string[];
};

export type WabaTemplateMessage = {
  template_locale: string;
  template_name: string;
  header_params?: WabaTemplateMessageContentHeader[];
  body_params?: string[];
  button_params?: string[];
  carousel_media_card_params?: WabaTemplateMessageCarousel[];
};

export type WabaTemplateSubmitCardData = {
  attachment: File;
  body?: StringValue[];
  buttons?: StringValue[];
};

type WabaTemplatePreselectData = {
  template: WabaTemplate;
  values: WabaTemplateParamsUpdate | undefined;
};

export const attachmentConfig = {
  document: {
    extensions: '.pdf, .txt, .docx, .doc, .rtf, .odt, .xml',
    sizeMB: 100,
  },
  image: {
    extensions: '.png, .jpeg, .jpg',
    sizeMB: 5
  },
  video: {
    extensions: '.mp4, .3gp',
    sizeMB: 16
  }
};

export const paramsOrder = ['header', 'body', 'buttons'];

export type WabaTemplateMessageState = {
  stage: WabaTemplateMessageStage;
  template: WabaTemplate | undefined;
  components: WabaTemplateMessageComponents | undefined;
  examples: WabaTemplateParamsUpdate | undefined;
  params: WabaTemplateMessageParams | undefined;
  message: WabaTemplateMessage | undefined;
};

export const initialState: WabaTemplateMessageState = {
  stage: 'select',
  template: undefined,
  components: undefined,
  examples: undefined,
  params: undefined,
  message: undefined
};

const wabaTemplateMessageActions = ['select', 'fullfill', 'reselect', 'form', 'params', 'prepare', 'preselect' ] as const;

type WabaTemplateMessageActions = typeof wabaTemplateMessageActions[number];

type WabaTemplateMessageActionBase<T extends WabaTemplateMessageActions,
  P extends Partial<WabaTemplateMessageState> | WabaTemplateParamsUpdate | WabaTemplateParamsData
  | WabaTemplatePreselectData| undefined> = {
  type: T;
  payload: P;
};

export type WabaTemplateMessageSelection = WabaTemplateMessageActionBase<'select', Pick<WabaTemplateMessageState, 'template'>>;
export type WabaTemplateMessageFullfill = WabaTemplateMessageActionBase<'fullfill', undefined>;
export type WabaTemplateMessageReselect = WabaTemplateMessageActionBase<'reselect', undefined>;
export type WabaTemplateMessageForm = WabaTemplateMessageActionBase<'form', undefined>;
export type WabaTempageMessageParams = WabaTemplateMessageActionBase<'params', WabaTemplateParamsUpdate>; 
export type WabaTemplateMessagePrepare = WabaTemplateMessageActionBase<'prepare', WabaTemplateParamsUpdate>;
export type WabaTemplateMessagePreselect = WabaTemplateMessageActionBase<'preselect', WabaTemplatePreselectData>;

export type WabaTemplateMessageAction = WabaTemplateMessageSelection | WabaTemplateMessageFullfill
  | WabaTemplateMessageReselect | WabaTemplateMessageForm | WabaTempageMessageParams
  | WabaTemplateMessagePrepare | WabaTemplateMessagePreselect;

export function wabaTemplateMessageReducer(
  state: WabaTemplateMessageState, action: WabaTemplateMessageAction): WabaTemplateMessageState {
  let message;
  switch (action.type) {
    case 'select':
      return {
        ...state,
        template: action.payload.template,
        examples: undefined,
        ...(action.payload.template && { params: wabaTemplateParams(action.payload.template) }),
        ...(action.payload.template &&  { components: wabaTemplatesComponents(action.payload.template) }),
        ...(action.payload.template && { message: wabaTemplateContent(action.payload.template) })
      };
    case 'preselect':
      return {
        ...state,
        stage: 'fullfilled',
        template: action.payload.template,
        ...(action.payload.values && { examples: action.payload.values }),
        ...(wabaTemplatePreselect(action.payload.template, action.payload.values))
      }
    case 'reselect':
      return { ...state, stage: 'select' };
    case 'form':
      return { ...state, stage: 'form', ...(state.template && { params: wabaTemplateParams(state.template) }) };
    case 'params':
      return { ...state,
        ...(state.template && state.components && state.params && {
          components: wabaTemplateUpdateComponents(state.template, state.components, state.params, action.payload) }) };
    case 'prepare':
      if (!state.template) return state;
      message = wabaTemplatePrepare(state.template, action.payload);
      if (!message) return state;
      return { ...state, stage: 'fullfilled', message };
    case 'fullfill':
      return { ...state, stage: 'fullfilled' };
    default:
      return state;
  }
}

export type WabaTemplateContext = {
  wabaTemplateState: WabaTemplateMessageState;
  updateWabaTemplateState: Dispatch<WabaTemplateMessageAction>;
};

export const wabaTemplateContext = createContext<WabaTemplateContext>({} as WabaTemplateContext);

function wabaTemplatesComponents(template: WabaTemplate): WabaTemplateMessageComponents {
  const components : WabaTemplateMessageComponents = {
    body: template.components.body.text,
    ...(template.components.header
      ? { header: { format: template.components.header?.format, text: template.components.header?.text } }
      : undefined
    ),
    ...(template.components.footer ? { footer: template.components.footer.text } : undefined),
  };
  
  if (template.components.buttons && template.components.buttons.buttons.length > 0) {
    const buttons: WabaTemplateMessageButtons = [];
    template.components.buttons.buttons.forEach((button) => {
      buttons.push({ ...button })
    });
    components.buttons = buttons;
  }

  if (template.components.carousel && template.components.carousel.cards.length > 0) {
    const cards: WabaTemplateMessageCard[] = [];
    template.components.carousel.cards.forEach((card) => {
      const buttons: WabaTemplateMessageButtons = [];
      card.buttons.buttons.forEach((button) => {
        buttons.push({ ...button })
      });
      cards.push({ body: card.body.text, header: { format: card.header.format }, buttons  })
    });
    components.carousel = cards;
  }

  return components;
}

export function hasWabaTemplateParams(template: WabaTemplate): boolean {
  return (template.components.header ? template.components.header.format !== 'text' : false)
    || template.components.body.paramsCount > 0
    || (template.components.header ? template.components.header.paramsCount > 0 : false)
    || (template.components.buttons ? template.components.buttons.paramsCount > 0 : false)
    || (template.components.footer ? template.components.footer.paramsCount > 0 : false)
    || (template.components.carousel ? template.components.carousel.cards.length > 0 : false);
}

function wabaTemplateContent(template: WabaTemplate): WabaTemplateMessage | undefined {
  if (hasWabaTemplateParams(template)) return undefined;

  return {
    template_locale: template.locale,
    template_name: template.name
  };
}

function getTextParams(text: string): ParamsType[] {
  const findParams = text.split(/{{(\d+)}}/gm);
  const numbers: number[] = [];
  const params: ParamsType[] = [];

  findParams.forEach((param, index) => {
    if (index % 2 === 1 && !numbers.includes(Number(param))) {
      numbers.push(Number(param));
      params.push({ id: Number(param), label: param, value: '' });
    }
  });

  return params;
};

function getButtonsUrlParams(buttons: WabaTemplateButton[]) {
  const params: ParamsType[] = [];
  buttons.forEach((item, index) => {
    const findParams = item.url.split(/{{(\d+)}}/gm);
    if (findParams[1]) params.push({ id: index, label: findParams[1], value: '' });
  });

  return params;
};

function wabaTemplateParams(template: WabaTemplate): WabaTemplateMessageParams | undefined {
  if (hasWabaTemplateParams(template)) {
    const params: WabaTemplateMessageParams = {};
    if (template.components.body.paramsCount > 0) {
      params.body = getTextParams(template.components.body.text);
    }
    if (template.components.header) {
      if (template.components.header.paramsCount > 0) {
        params.header = getTextParams(template.components.header.text);
      } else if (template.components.header.format !== 'text') {
        params.attachment = undefined;
      }
    }
    if (template.components.buttons) {
      params.buttons = getButtonsUrlParams(template.components.buttons.buttons.filter(
        (item) => item.type === 'url'));
    }
    if (template.components.carousel?.cards) {
      params.carousel = template.components.carousel.cards.map((card) => {
        return {
          attachment: undefined,
          body: getTextParams(card.body.text),
          buttons: getButtonsUrlParams(card.buttons.buttons.filter((item) => item.type === 'url'))
        }
      });
    }
    return params;
  }

  return undefined;
}

const replaceTextParams = (values: string[], params: ParamsType[] | undefined, component: string) => {
  if (!params) return component;
  values.forEach((param, index) => {
    if (param && params[index]) component = component.replace(`{{${params[index].id}}}`, param);
  });

  return component;
};

function wabaTemplateUpdateComponents(
  template: WabaTemplate,
  components: WabaTemplateMessageComponents,
  params: WabaTemplateMessageParams,
  data: WabaTemplateParamsUpdate): WabaTemplateMessageComponents {

    const contents: WabaTemplateMessageComponents = { ...components };

    Object.keys(data).forEach((item) => {
      if (item === 'body') {
        contents[item] = 
          replaceTextParams(data[item]
            .map((item: { value: string }) => item.value), params[item], template.components.body.text);
      }
      if (item === 'header' && contents.header?.format === 'text') {
        contents.header.text = replaceTextParams(data[item]
          .map((item: { value: string }) => item.value), params[item], template.components.header?.text ?? '');
      }
      if (item === 'attachment' && contents.header) {
        contents.header.file = data.attachment;
      }
      if (item === 'carousel') {
        if (contents.carousel !== undefined && contents.carousel.length > 0) {
          const carousel = data[item].map((card, index) => {
            return { ...(contents.carousel && contents.carousel[index]),
              header: {
                format: template.components.carousel?.cards[index].header.format ?? 'image',
                file: card.attachment
              },
              ...(contents.carousel && contents.carousel[index].body && card.body && { 
                body: replaceTextParams( card.body.map((item: { value: string }) => item.value),
                params.carousel && params.carousel[index].body,
                template.components.carousel?.cards[index].body.text ?? '') })
            }
          });
          if (contents.carousel) contents.carousel = carousel;
        }
      }
    });

    return contents;
  }

  function wabaTemplatePrepare(template: WabaTemplate, data: WabaTemplateParamsUpdate):
    WabaTemplateMessage | undefined {

    const cards: WabaTemplateMessageCarousel[] = [];
    if (data.carousel) {
      data.carousel.forEach((card) => {
        if (!card.attachment) return;
        cards.push({
          header: card.attachment,
          body_params: card.body && card.body.map((item: { value: string }) => item.value),
          button_params: card.buttons && card.buttons.map((item: { value: string }) => item.value)
        });
      });
      if (cards.length !== data.carousel.length) return undefined;
    }
    const message: WabaTemplateMessage = {
      template_locale: template.locale,
      template_name: template.name,
      ...(data.body && { body_params: data.body.map((item: { value: string }) => item.value) }),
      ...(data.header && { header_params: data.header.map((item: { value: string }) => ({ text: item.value })) }),
      ...(data.attachment && { header_params: [{ file: data.attachment }] }),
      ...(data.buttons && { button_params: data.buttons.map((item: { value: string }) => item.value) }),
      ...(cards && {
        carousel_media_card_params: cards.map((item) => ({
          header: item.header,
          body_params: item.body_params,
          button_params: item.button_params
        }))
      })
    };

    return message;
  }

  function wabaTemplatePreselect(template: WabaTemplate, examples?: WabaTemplateParamsUpdate) {
    const params = wabaTemplateParams(template);
    let components = wabaTemplatesComponents(template);
    if (params && examples) {
      const message =  wabaTemplatePrepare(template, examples);
      components = wabaTemplateUpdateComponents(template, components, params, examples);
      return { components, message, params };
    } else {
      const message = wabaTemplateContent(template);
      return { components, message };
    }
  }
  