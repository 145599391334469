import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useHasPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import './DialogsWarn.scss';


function DialogsWarn() {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const hasPermissionSubscription = useHasPermission('subscriptionsView');

  return (
    <p className='dialogsWarn'>
      {t('broadcasts.error.no_waba_dialogs')}
      {hasPermissionSubscription
        ? <Link to={`${url?.url}/settings/subscriptions`}>{t('settings.settings_list.subscriptions')}</Link>
        : t('settings.settings_list.subscriptions')
      }
      . {t('broadcasts.form.deferred')}
    </p>
  );
}

export default DialogsWarn;
