import { FileMessage } from '../../../../../api/messages/types';
import VideoView from '../../../../../components/VideoView';
import './MessageDisplayVideo.scss';


type MessageDisplayVideoProps = {
  message: FileMessage;
};

function MessageDisplayVideo({ message }: MessageDisplayVideoProps) {
  return (
    <div className='messageDisplayVideo'>
      <p className='regularText'>{message.caption}</p>
      <VideoView attachedFileUrl={message.url}/>
    </div>
  );
}

export default MessageDisplayVideo;
