import React from 'react';
import VideoView from '../VideoView';
import './FilePreview.scss';
import { ReactComponent as ImageIcon } from '../../assets/image.svg';
import { ReactComponent as VideoIcon } from '../../assets/video.svg';
import { ReactComponent as FilesIcon } from '../../assets/files.svg';
import { ReactComponent as PDFIcon } from '../../assets/pdf.svg';


type FilePreviewProps = {
  format: string;
  file?: File | string;
};

const icons = {
  image: ImageIcon,
  video: VideoIcon,
  document: FilesIcon
};

function FilePreview({ format, file }: FilePreviewProps) {

  return (
    <div className='filePreview'>
      {file
        ? (<>
          {format ===  'image' && <img alt="preview" src={typeof file === 'string' ? file: URL.createObjectURL(file)} />}
          {format === 'video' &&
            <VideoView attachedFile={typeof file === 'object' ? file : undefined}
              attachedFileUrl={typeof file === 'string' ? file : undefined}/>}
          {format === 'document' && typeof file === 'object' && (
            <div className="filePreview__document">
              {file.type === 'application/pdf' ? <PDFIcon /> : <FilesIcon />}
              <span className="smallText">{file.name}</span>
            </div>
          )}
        </>) : (<>
          {Object.keys(icons).includes(format) && React.createElement(icons[format])}
        </>)
      }
    </div>
  );
}

export default FilePreview;
