import './Carousel.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WabaTemplateCard } from '../../../../../../api/templates/types';
import { paramsOrder, WabaTemplateMessageCardParams, WabaTemplateParamsData } from '../../data';
import Attachment from '../Attachment';
import ParametersList from '../ParametersList';


type CarouselProps = {
  carousel: WabaTemplateCard[];
  cards: WabaTemplateMessageCardParams[];
  attachments: Array<File | undefined>;
  setAttachments: React.Dispatch<React.SetStateAction<Array<File | undefined>>>;
};

function Carousel({ carousel, cards, attachments, setAttachments }: CarouselProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<WabaTemplateParamsData>();
  const cardsFields = useFieldArray<WabaTemplateParamsData>({ control, name: 'carousel' });

  const setCardAttachemnt = (cardIndex: number) => {
    return (file: File | undefined) => {
      setAttachments((prev) => {
        return [...prev.slice(0, cardIndex), file, ...prev.slice(cardIndex + 1)];
      });
    };
  };

  return (
    <div className='carousel'>
      {cardsFields.fields?.map((card, index) => {
        return (
          <div key={card.id} className='carousel__card'>
            <h4>{t('broadcasts.form.fill_template.carousel.title')}&nbsp;{index + 1}</h4>
            <Attachment format={carousel[index].header.format} field={`carousel.${index}.header`}
              attachment={attachments[index]} setAttachment={setCardAttachemnt(index)} />
            {paramsOrder.map((param) => {
              if (!card[param]) return null;
              return <ParametersList key={param} component={`carousel.${index}.${param}`}
                label={t(`broadcasts.form.fill_template.carousel.${param}`)} params={cards[index][param]} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Carousel;
