import { useContext, useEffect, useRef } from 'react';
import './TemplateItem.scss';
import { WabaTemplate } from '../../../../../api/templates/types';
import { wabaTemplateContext } from '../data';


type TemplateItemProps = {
  template: WabaTemplate;
};

const TemplateItem = ({ template }: TemplateItemProps) => {
  const { wabaTemplateState, updateWabaTemplateState } = useContext(wabaTemplateContext);
  const ref = useRef<HTMLDivElement>(null);

  const onTemplate = () => {
    updateWabaTemplateState({ type: 'select', payload: { template } });
  };
  
  const active = wabaTemplateState.template?.name === template?.name;
  
  useEffect(() => {
    if (active && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className={`templateItem ${active ? 'active' : ''}`} ref={ref} onClick={onTemplate}>
      <p className="smallText bold">
        {template.customName ? template.customName : template.name}
      </p>
      <p className="smallText">{template?.components?.body.text}</p>
    </div>
  );
};

export default TemplateItem;
