import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ContactNotesType } from '../../ContactsAPI';
import { UsersType } from '../../../../api/CompanyAPI';
import { removeNote } from '../../contactsSlice/contactsSlice';
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';
import { getHourMinIfCreatedUpdatedInOneDay } from '../../../../utils/utils';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import NoteForm from '../NoteForm';


type Props = {
  note: ContactNotesType;
  members: UsersType[];
};

export const Note: React.FC<Props> = ({ note, members }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [editNote, setEditNote] = useState<boolean>(false);

  const activeButtonRemove = (c: ContactNotesType) => {
    dispatch(removeNote({ contact_id: c.contactId, note_id: c.id, companyId: Number(url?.params.companyId) }));
  };

  const getMember = (memberId: number | null | undefined) => {
    if (memberId) {
      const objMember = members.find((member) => member.id === memberId);
      if (objMember) {
        return objMember.displayName;
      }
    }
    return null;
  };

  const createdAt = note.createdAt ? getDateTime().format(note.createdAt, 'dayMonthYear') : '';
  const updateAt = note.updatedAt ? getDateTime().format(note.updatedAt, 'dayMonthYear') : '';

  const hourMin = getHourMinIfCreatedUpdatedInOneDay(note.createdAt,note.updatedAt);
  const memberWrite = getMember(note.updatedBy);

  return (
    <div className="note">
      <div className="note__title">
        <p className="regularText">{note.text}</p>
      </div>
      <div className="note__timeAt">
        <div className="note__timeAt_create">
          <p className="smallText bold">{t('contacts.detailContact.creation')}:</p>
          <p className="smallText">{createdAt}, {getMember(note.createdBy)}{' '}</p>
        </div>
        {note.createdAt !== note.updatedAt && (
          <div className="note__timeAt_update">
            <p className="smallText bold">{t('contacts.detailContact.changed')}:</p>
            <p className="smallText">{updateAt}{`${hourMin}, ${memberWrite}`}</p>
          </div>
        )}
      </div>
      <WrapperPermission permission="contactsEdit">
        <div className="note__buttons">
          <button className="note__buttons_edit" type="button" data-testid='edit'
            onClick={() => setEditNote(!editNote)} >
            <EditIcon />
            <p className="smallText">{t('contacts.detailContact.edit')}</p>
          </button>
          <button className="note__buttons_delete" type="button" onClick={() => activeButtonRemove(note)}
            data-testid='delete'>
            <DeleteIcon />
            <p className="smallText">{t('contacts.detailContact.remove')}</p>
          </button>
        </div>
      </WrapperPermission>
      {editNote && <NoteForm contactId={note.contactId} noteId={note.id} togglePopUp={() => setEditNote(false)} />}
    </div>
  );
};
