import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as typ from "./SubscriptionsTypes";
import * as api from "./SubscriptionsAPI";


const DEFAULT_INVOICES_LIMIT = 10;

export type SubscriptionsSlice = {
  subscriptions: typ.Subscription[] | [];
  subscription: typ.Subscription | null;
  requisites: typ.PaymentRequisite[];
  requisite: typ.PaymentRequisite | null;
  invoices: typ.SubscriptionInvoice[] | null;
  invoice: typ.SubscriptionInvoice | null;
  dialogs: typ.SubscriptionDialogs[] | null;
  isLoader: boolean;
  hasMoreInvoices: boolean;
  numberInvoices: number;
};

const initialState: SubscriptionsSlice = {
  subscriptions: [],
  subscription: null,
  requisites: [],
  requisite: null,
  invoices: null,
  invoice: null,
  dialogs: null,
  isLoader: false,
  hasMoreInvoices: false,
  numberInvoices: DEFAULT_INVOICES_LIMIT,
};

export const fetchSubscriptions =
  createAsyncThunk<typ.Subscription[], { companyId: number }, { rejectValue: number }>(
    'subscriptions/fetchSubscriptions', async (requestOptions,{ rejectWithValue }) => {
      try {
        return await api.getSubscriptions(requestOptions.companyId);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

type UpdateSubscriptionPayload = {
  subscriptionId: number;
  companyId: number;
  data: typ.PatchSubscription;
};

export const updateSubscriptions =
  createAsyncThunk<typ.Subscription, UpdateSubscriptionPayload, { rejectValue: number }>( 
    'subscriptions/updateSubscriptions',
    async (payload, { rejectWithValue }) => {
      try {
        return await api.patchSubscriptions(payload.subscriptionId, payload.companyId, payload.data);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchSubscriptionInvoice =
  createAsyncThunk<typ.SubscriptionInvoice[], typ.SubscriptionsInvoicesRequest, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionInvoice', async (requestOptions, { dispatch, rejectWithValue }) => {
      try {
        const { companyId, limit, offset } = requestOptions;
        let requestLimit: number;
        if (limit === undefined) {
          requestLimit = DEFAULT_INVOICES_LIMIT;
        } else {
          requestLimit = limit;
        }
        dispatch(setNumberInvoicesFetching(requestLimit));
        return await api.getSubscriptionInvoice({ companyId, limit: requestLimit + 1, offset });
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const addSubscriptionInvoice =
  createAsyncThunk<typ.SubscriptionInvoice, typ.PostSubscriptionInvoice, { rejectValue: number }>(
    'subscriptions/addSubscriptionInvoice',
    async (payload, { rejectWithValue }) => {
      try {
        return await api.postSubscriptionInvoice(payload);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

type SubscriptionInvoicesRequest = [Promise<typ.Subscription[]>, Promise<typ.SubscriptionInvoice[]>];

const MIN_INVOICES_NUMBER = 3;

export const fetchSubscriptionsInfo =
  createAsyncThunk<undefined, { companyId: number }, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionsInfo',
    async (requestOption, { dispatch, rejectWithValue }) => {
      dispatch(clearSubscriptionsInfo());
      const requests: SubscriptionInvoicesRequest = [
        api.getSubscriptions(requestOption.companyId),
        api.getSubscriptionInvoice({ companyId: requestOption.companyId, limit: MIN_INVOICES_NUMBER + 1 })
      ]
      try {
        return await Promise.all(requests)
          .then((response) => {
            dispatch(updateSubscriptionsInfo(response));
            return undefined;
          });
      }
      catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchSubscriptionDialogs =
  createAsyncThunk<typ.SubscriptionDialogs[], typ.SubscriptionDialogsRequest, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionDialogs',
    async (requestOption, { rejectWithValue }) => {
      try {
        return await api.getSubscriptionDialogs(requestOption);
      }
      catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchPaymentRequisite =
 createAsyncThunk<typ.PaymentRequisite[], { company_id: number }, { rejectValue: number }>(
  'subscriptions/fetchPaymentRequisite', async (requestOptions,{ rejectWithValue }) => {
    try {
      return await api.getPaymentRequisite(requestOptions.company_id);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const addPaymentRequisite =
  createAsyncThunk<typ.PaymentRequisite, typ.PostPaymentRequisite, { rejectValue: number }>(
  'subscriptions/addPaymentRequisite',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.postPaymentRequisite(payload);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updatePaymentRequisite =
  createAsyncThunk<typ.PaymentRequisite, typ.UpdatePaymentRequisite, { rejectValue: number }>(
  'subscriptions/updatePaymentRequisite',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.patchPaymentRequisite(payload);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removePaymentRequisite =
  createAsyncThunk<typ.PaymentRequisite, {company_id: number, requisite_id: number}, { rejectValue: number }>(
  'subscriptions/removePaymentRequisite',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.deletePaymentRequisite(payload.company_id, payload.requisite_id);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    clearSubscriptionPayInvoice: (state) => {
      state.invoice = null;
    },
    clearSubscriptionsInfo: (state) => {
      state.invoices = [];
      state.subscriptions = [];
      state.isLoader = true;
    },
    updateSubscriptionsInfo: (state, action: PayloadAction<[typ.Subscription[], typ.SubscriptionInvoice[]]>) => {
      const [subscriptions, invoices] = action.payload;
      state.isLoader = false;
      state.subscriptions = subscriptions;
      // limit first portion of invoices by MIN_INVOICES_NUMBER
      // but if there are more than MIN_INVOICES_NUMBER of invoices,
      // then set hasMoreInvoices flag
      const hasMoreInvoices = invoices.length > MIN_INVOICES_NUMBER;
      if (hasMoreInvoices) {
        state.invoices = invoices.slice(0, MIN_INVOICES_NUMBER);
      } else {
        state.invoices = invoices;
      }
      state.hasMoreInvoices = hasMoreInvoices
    },
    setNumberInvoicesFetching: (state, action: PayloadAction<number>) => {
      state.numberInvoices = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.fulfilled, (state, action: PayloadAction<typ.Subscription[]>) => {
        state.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptionInvoice.fulfilled, (state, action: PayloadAction<typ.SubscriptionInvoice[]>) => {
        if (state.invoices !== null) {
          const invoices = action.payload
          const hasMoreInvoices = invoices.length > state.numberInvoices;
          if (hasMoreInvoices) {
            state.invoices.push(...invoices.slice(0, state.numberInvoices));
          } else {
            state.invoices.push(...action.payload);
          }
          state.hasMoreInvoices = hasMoreInvoices;
        } else {
          state.invoices = action.payload;
        }
      })
      .addCase(addSubscriptionInvoice.fulfilled, (state, action: PayloadAction<typ.SubscriptionInvoice>) => {
        if (!action.payload.preview) {
          if (state.invoices !== null) {
            state.invoices.unshift(action.payload);
          } else {
            state.invoices = [action.payload];
          }
        }
        state.invoice = action.payload;
      })
      .addCase(updateSubscriptions.fulfilled, (state, action) => {
        if (!action.meta.arg.data.preview) {
          if (state.subscriptions) {
            const index = state.subscriptions.findIndex((item) => item.id === action.payload.id);
            if (index !== -1) state.subscriptions[index] = action.payload;
          }
        }
      })
      .addCase(fetchSubscriptionDialogs.fulfilled, (state, action: PayloadAction<typ.SubscriptionDialogs[]>) => {
        state.dialogs = action.payload;
      })
      .addCase(fetchPaymentRequisite.fulfilled, (state, action: PayloadAction<typ.PaymentRequisite[]>) => {
        state.requisites = action.payload;
      })
      .addCase(addPaymentRequisite.fulfilled, (state, action: PayloadAction<typ.PaymentRequisite>) => {
        state.requisites.unshift(action.payload);
      })
      .addCase(updatePaymentRequisite.fulfilled, (state, action: PayloadAction<typ.PaymentRequisite>) => {
        const idIndex = state.requisites.findIndex((requisite) => requisite.id === action.payload.id);
        state.requisites[idIndex] = action.payload;
      })
      .addCase(removePaymentRequisite.fulfilled, (state, action) => {
        const idIndex = state.requisites.findIndex((requisite) => requisite.id === action.meta.arg.requisite_id);
        state.requisites.splice(idIndex, 1);
      });
  },
});

export const {
  updateSubscriptionsInfo,
  clearSubscriptionsInfo,
  clearSubscriptionPayInvoice,
  setNumberInvoicesFetching,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
