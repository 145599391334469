import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { broadcastsItemSelector } from '../BroadcastsSelector';
import { IBroadcastMessage } from '../BroadcastsTypes';
import { BroadcastMessage, BroadcastMessageFile, BroadcastMessageWabaTemplate } from './types';


const isChangedBroadcastMessage = (broadcastMessage: IBroadcastMessage, newMessage: BroadcastMessage): boolean => {
  if (broadcastMessage.messageType !== newMessage.message_type) return true;
  if (broadcastMessage.messageType === 'text' && newMessage.message_type === 'text') 
      return broadcastMessage.text?.text !== newMessage.text;
  if (broadcastMessage.messageType === newMessage.message_type
    && ['image', 'video', 'file'].includes(newMessage.message_type)) {
    const message = newMessage as BroadcastMessageFile;
    return broadcastMessage[broadcastMessage.messageType]?.caption !== message.caption
      || broadcastMessage[broadcastMessage.messageType]?.url !== message.file;
  }
  if (broadcastMessage.messageType === 'waba_template' && newMessage.message_type === 'waba_template') {
    const message = newMessage as BroadcastMessageWabaTemplate;
    return JSON.stringify(broadcastMessage.wabaTemplate) !== JSON.stringify(message.waba_template);
  }
  return false;
};

export function useMessage() {
  const broadcast = useSelector(broadcastsItemSelector);
  const [broadcastMessage, setBroadcastMessage] = useState<BroadcastMessage>();
  const [isChangedMessage, setIsChangedMessage] = useState<boolean>(false);

  useEffect(() => {
    if (broadcast && broadcastMessage) {
      setIsChangedMessage(isChangedBroadcastMessage(broadcast.message, broadcastMessage));
    } else if (broadcastMessage) {
      setIsChangedMessage(true);
    }
  }, [broadcast, broadcastMessage]);

  return { broadcastMessage, setBroadcastMessage, isChangedMessage };
}
