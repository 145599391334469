import './TemplateWabaPreview.scss';
import { WabaTemplateMessageComponents } from "../data";
import TemplateWabaBody from "./TemplateWabaBody";
import TemplateWabaButtons from "./TemplateWabaButtons";
import TemplateWabaCarousel from "./TemplateWabaCarousel";
import TemplateWabaFooter from "./TemplateWabaFooter";
import TemplateWabaHeader from "./TemplateWabaHeader";
import { ReactComponent as MessageCheckmark } from '../../../../../assets/message-checkmark.svg';
import { ReactComponent as Tail } from '../../../../../assets/message-tail.svg';


type TemplateWabaPreviewProps = {
  components: WabaTemplateMessageComponents;
};

function TemplateWabaPreview({ components: { header, body, footer, buttons, carousel } }: TemplateWabaPreviewProps) {
  return (
    <div className="templateWabaPreview">
      <div className="templateWabaPreview__content">
        {header && <TemplateWabaHeader header={header}/>}
        <TemplateWabaBody body={body}/>
        {footer && <TemplateWabaFooter footer={footer}/>}
        <div className="messageDate">{'12:15'}<MessageCheckmark /></div>
        <Tail className="templatePreview__content_tail"/>
      </div>
      {carousel && <TemplateWabaCarousel carousel={carousel} />}
      {buttons && <TemplateWabaButtons buttons={buttons} />}
    </div>
  );
}

export default TemplateWabaPreview;
