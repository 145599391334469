import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertStyle } from '../Alert/alertSlice';
import { ReactComponent as CloseIcon } from '../../assets/cross.svg';
import './AlertMessage.scss';


type AlertMessageProps = {
  type: AlertStyle;
  message?: string;
  title?: string;
  close?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
};

function AlertMessage({ type, message, title, close = false, children, onClose }: AlertMessageProps) {
  const { t } = useTranslation();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div className={`alertMessage ${type} ${title ? 'title' : ''}`}>
      {title && <h2>{t(title)}</h2>}
      {close && <CloseIcon onClick={onCloseHandler} />}
      {message && <p className={title ? 'title' : ''}>{t(message)}</p>}
      {children}
    </div>
  );
}

export default AlertMessage;
