import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../../../components/RadioControl';
import { ReactComponent as InfoIcon } from '../../../../assets/info.svg';
import './NumbersOptions.scss';


type NumbersOptionsProps = {
  name: string;
};

const numbersOptions = ['numbers_last', 'numbers_all'] as const;  // from_active_chat

type NumbersOptionsType = {
  value: 'true' | 'false';
  info?: boolean;
};

const numbersOptionsValues: Record<typeof numbersOptions[number], NumbersOptionsType> = {
  numbers_last: { value: 'true', info: true },
  numbers_all: { value: 'false' }
};

function NumbersOptions({ name }: NumbersOptionsProps) {
  const { t } = useTranslation();

  return (
    <div className='numbersOptions'>
      <p className='regularText middle'>{t('broadcasts.form.numbers_text')}</p>
      {numbersOptions.map((option, index) =>
        (<div className='numbersOptions__option' key={nanoid()}>
          <RadioButton key={option} label={t(`broadcasts.form.${option}`)} name={name}
            defaultChecked={index === 0} value={numbersOptionsValues[option].value} />
          {numbersOptionsValues[option].info &&
            <div className='numbersOptions__info'>
              <InfoIcon />
              <div className='numbersOptions__info_message'>{t(`broadcasts.form.${option}_info`)}</div>
            </div>
          }
        </div>)
      )}
    </div>
  );
}

export default NumbersOptions;
