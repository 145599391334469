import { useContext, useEffect, useState } from 'react';
import './TemplateParams.scss';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  paramsOrder, ParamsType, StringValue, wabaTemplateContext, WabaTemplateMessageCardData,
  WabaTemplateMessageCardParams, WabaTemplateMessageParams, WabaTemplateParamsData, WabaTemplateParamsUpdate
} from '../data';
import { useParamsSchema } from '../schemas';
import Button from '../../../../../components/Button';
import ParametersList from './ParametersList';
import Attachment from './Attachment';
import Carousel from './Carousel';


type TemplateParamsProps = {
  params: WabaTemplateMessageParams;
  onSave: (data: WabaTemplateParamsUpdate) => void;
};

const checkParams = (params: unknown): params is ParamsType[] => {
  return Array.isArray(params) && params[0]?.id !== undefined;
};

const checkCarousel = (params: unknown): params is WabaTemplateMessageCardParams[] => {
  return Array.isArray(params) && params[0]?.attachment === undefined;
};

function TemplateParams({ params, onSave }: TemplateParamsProps) {
  const { t } = useTranslation();
  const paramsKeys = Object.keys(params) as Array<keyof WabaTemplateParamsData>;
  type ParamsForm = Pick<WabaTemplateParamsData, Extract<WabaTemplateParamsData, typeof paramsKeys[number]>>;
  const schema = useParamsSchema(params);
  const { wabaTemplateState: { template, examples }, updateWabaTemplateState } = useContext(wabaTemplateContext);
  const [attachment, setAttachment] = useState<File | undefined>();
  const [attachments, setAttachments] = useState<Array<File | undefined>>(
    Array(params.carousel?.length ?? 0).fill(undefined));

  const getDefault = () => {
    let defaultValue: ParamsForm = {} as ParamsForm;
    paramsKeys.forEach((key) => {
      if (Array.isArray(params[key]) && checkParams(params[key])) {
        defaultValue[key] = Array((params[key] as StringValue[])?.length).fill({ value: '' });
      }
      if (params[key] === undefined) defaultValue[key] = undefined;
      if (Array.isArray(params[key]) && checkCarousel(params[key])) {
        defaultValue[key] = (params[key] as WabaTemplateMessageCardParams[])?.map((param) => (
          { attachment: undefined,
            ...(param.body && param.body.length > 0 &&
              { body: Array((param.body as StringValue[])?.length).fill({ value: '' }) }),
            ...(param.buttons && param.buttons.length > 0 &&
              { buttons: Array((param.buttons as StringValue[])?.length).fill({ value: '' }) }) }
        ));
      }
      if (examples) {
        defaultValue = { ...examples };
      }
    });
    return defaultValue;
  };

  const defaultValue = getDefault();

  const formKeys = Object.keys(defaultValue);

  const methods = useForm<ParamsForm>({ mode: 'onSubmit', resolver: yupResolver(schema), defaultValues: defaultValue });

  const data = useWatch<ParamsForm>({ control: methods.control });

  const formUpdateData = (data: ParamsForm) => {
    const updateData: WabaTemplateParamsUpdate = {} as WabaTemplateParamsUpdate;

    formKeys.forEach((key) => {
      if (key === 'attachment') {
        updateData[key] = attachment;
      } else if (key === 'carousel' && Array.isArray(data[key])) {
        updateData[key] = data[key].map((card: WabaTemplateMessageCardData, index: number) => {
          return {
            attachment: attachments[index],
            ...(card.body && card.body.length > 0 &&
              { body: card.body }),
            ...(card.buttons && card.buttons.length > 0 &&
              { buttons: card.buttons })
          };
        });
      } else {
        updateData[key] = data[key];
      }
    });
    return updateData;
  };

  useEffect(() => {
    updateWabaTemplateState({ type: 'params', payload: formUpdateData(data) });
  }, [data]);

  const submitHandler = (data: ParamsForm) => {
    onSave(formUpdateData(data));
  };

  return (
    <FormProvider {...methods}>
      <div className='templateParams'>
        {formKeys.includes('attachment') && template?.components.header?.format &&
          <Attachment field='attachment' format={template.components.header.format}
            attachment={attachment} setAttachment={setAttachment} />
        }
        {paramsOrder.map((param) => {
          if (!formKeys.includes(param)) return null;
          return <ParametersList key={param} component={param}
            label={t(`broadcasts.form.fill_template.${param}`)} params={params[param]} />;
        })}
        {formKeys.includes('carousel') && params.carousel && template?.components.carousel &&
          <Carousel carousel={template?.components.carousel?.cards} cards={params.carousel}
            attachments={attachments} setAttachments={setAttachments} />
        }
      </div>
      <Button type='submit' color='orange' textType='small' text={t('broadcasts.form.add_template')}
        onClick={methods.handleSubmit(submitHandler)} disabled={!methods.formState.isDirty} />
    </FormProvider>
  );
}

export default TemplateParams;
