import './ParametersList.scss';
import { useFieldArray, UseFieldArrayProps, useFormContext } from 'react-hook-form';
import { ParamsType, WabaTemplateParamsData } from '../../data';
import Input from '../../../../../../components/Input';


type ParametersListProps = {
  component: string;
  label: string;
  params: ParamsType[];
};

function ParametersList({ component, label, params }: ParametersListProps) {
  const methods = useFormContext<WabaTemplateParamsData>();
  const paramsFields = useFieldArray<WabaTemplateParamsData>(
    { control: methods.control, name: component as UseFieldArrayProps<WabaTemplateParamsData>['name'] });
  
  return (<>
    {paramsFields.fields?.map((field, index) => {
      return <Input key={`${field.id}-${index}`} name={`${component}.${index}.value`} 
        label={`${label} {{${params[index].label}}}`}/>
    })}
  </>);
}

export default ParametersList;
