import { FC, useContext, useState } from 'react';
import './ChatHeader.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/store';
import { updateIsPageBill } from '../../billSlice';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { useMobile } from "../../../../components/utils/hooks";
import { chatContext } from '../ChatContent/StickyViewport/Context';
import { ReactComponent as ThreeDotsIcon } from '../../../../assets/dot-more.svg';
import { ReactComponent as ReceiptIcon } from '../../../../assets/receipt.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/chat-group.svg';
import ChatHeaderModal from '../ChatHeaderModal/ChatHeaderModal';
import Button from '../../../../components/Button';
import Avatar from "../../../../components/Avatar";


type TypeProps = {
  toggleVisibleProfile: () => void;
};

const ChatHeader: FC<TypeProps> = ({ toggleVisibleProfile }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
    '/companies/:companyId/chats/:contactId/:chatId');
  const { contactName, avatarUrl, isGroup, chatPhone } = useContext(chatContext);
  const [toggle, setToggle] = useState(false);
  const isMobile = useMobile();

  const toggleProfile = () => {
    toggleVisibleProfile();
    setToggle(false);
  };

  const getImage = () => {
    if (isGroup) {
      return !avatarUrl ? <GroupIcon /> : avatarUrl;
    }
    return avatarUrl ?? ''
  };

  return (
    <div className="chatHeader">
      <div className="chatHeader__avatar" onClick={toggleVisibleProfile}>
        <Avatar image={getImage()} name={contactName ?? ''} />
      </div>
      <div className="chatHeader__userInfo" onClick={toggleVisibleProfile}>
        <p className="chatHeader__userInfo_name">{contactName ?? ''}</p>
        {chatPhone && (
          <p className="chatHeader__userInfo_phone">{chatPhone}</p>
        )}
      </div>
      {(!isMobile && !isGroup) &&
        <WrapperPermission permission="banksInvoiceCreate">
          <div className="chatHeader__bill">
            <Link to={`${url?.url}/bill`}>
              <Button type='button' color="white" textType="small" text={!isMobile ? t('chats.bill.title_bill') : ''}
                image={<ReceiptIcon />} onClick={() => {dispatch(updateIsPageBill(true))}} />
            </Link>
          </div>
        </WrapperPermission>}
      <div className="chatHeader__threeDots" onClick={() => setToggle(!toggle)}>
        <ThreeDotsIcon />
      </div>
      {toggle && (
        <div className="chatHeader__wrapperModal">
          <ChatHeaderModal toggleProfile={toggleProfile} toggleVisible={() => setToggle(false)}
            isGroup={isGroup ?? false} />
        </div>
      )}
    </div>
  );
};

export default ChatHeader;
