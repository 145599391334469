import { useTranslation } from 'react-i18next';
import PlanList from '../PlanList';
import './BroadcastsPlan.scss';


function BroadcastsPlan() {
  const { t } = useTranslation();
  
  return (
    <div className='broadcastPlan'>
      <h5>{t('broadcasts.plan.title')}</h5>
      <div className='plan_list'>
        <div className='plan_list__header'>
          <div>{t('broadcasts.params.name_date')}</div>
          <div>{t('broadcasts.params.connection')}</div>
          <div>{t('broadcasts.params.change_last')}</div>
        </div>
        <PlanList />
      </div>
    </div>
  );
}

export default BroadcastsPlan;
