import { bdAnchorsTariffs } from '../../../../../pages/Home/utils/consts';
import { ReactComponent as NewWindowIcon } from '../../../../../assets/new-window.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';


const TariffButton = () => {
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);

  return (
    <a target="_blank" rel="noreferrer" href={bdAnchorsTariffs[currentCompany.paymentCurrency]}>
      <NewWindowIcon />
      <p>{t('subscriptions.tariffs')}</p>
    </a>
  );
};

export default TariffButton;
