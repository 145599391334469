import './MessageDisplay.scss';
import { FileMessage, TextMessage, WabaTemplateMessage } from '../../../../api/messages/types';
import { IBroadcastDisplayMessage, IBroadcastTypeMessage } from '../../BroadcastsTypes';
import MessageDisplayFile from './MessageDisplayFile';
import MessageDisplayImage from './MessageDisplayImage';
import MessageDisplayText from './MessageDisplayText';
import MessageDisplayVideo from './MessageDisplayVideo';
import MessageDisplayWaba from './MessageDisplayWaba';


type MessageDisplayProps = {
  messageType: IBroadcastTypeMessage;
  displayMessage: IBroadcastDisplayMessage;
};

function MessageDisplay({ displayMessage, messageType }: MessageDisplayProps) {
  switch (messageType) {
    case 'image':
      return <MessageDisplayImage message={displayMessage.image as FileMessage} />;
    case 'text':
      return <MessageDisplayText message={displayMessage.text as TextMessage} />;
    case 'video':
      return <MessageDisplayVideo message={displayMessage.video as FileMessage} />;
    case 'file':
      return <MessageDisplayFile message={displayMessage.file as FileMessage} />;
    case 'waba_template':
      return <MessageDisplayWaba message={displayMessage.wabaTemplate as WabaTemplateMessage} />;
  };
}

export default MessageDisplay;
