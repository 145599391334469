import { useState } from "react";
import './TemplateList.scss';
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { WabaTemplate } from "../../../../../api/templates/types";
import Search from "../../../../../components/Search";
import TemplateItem from "../TemplateItem";


type TemplateListProps = {
  templates: WabaTemplate[];
};

function TemplateList({ templates }: TemplateListProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [query] = useDebounce(search, 400);

  return (
    <div className="templateList">
      <h4>{t('broadcasts.form.choose_template')}</h4>
      <Search onChangeSearchInput={setSearch} />
      <div className="templateList__list">
        {templates && templates.length > 0 &&
          templates.filter((t) =>
            t.name.toLowerCase().includes(query.toLowerCase()) ||
            t.customName?.toLowerCase().includes(query.toLowerCase()) ||
            t.components?.body?.text.toLowerCase().includes(query.toLowerCase()))
            .map((template) => <TemplateItem key={template.name} template={template} />)}
      </div>
    </div>
  );
}

export default TemplateList;
