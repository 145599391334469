import { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTagsList } from '../Settings/Tags/TagsSlice';
import Broadcasts from '.';
import BroadcastsForm from './BroadcastsForm';
import BroadcastPreview from './BroadcastPreview';


function BroadcastsRoute() {
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/broadcasts');

  useEffect(() => {
    if (url?.params.companyId) {
      dispatch(getTagsList({ companyId: Number(url.params.companyId) }));
    }
  }, [url?.params]);

  return (
    <Switch>
      <Route path={url?.url} exact>
        <Broadcasts />
      </Route>
      <Route path={`${url?.url}/create`} exact>
        <BroadcastsForm />
      </Route>
      <Route path={`${url?.url}/edit/:id`}>
        <BroadcastsForm />
      </Route>
      <Route path={`${url?.url}/:id`}>
        <BroadcastPreview />
      </Route>
    </Switch>
  )
}

export default BroadcastsRoute;
