import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { broadcastsLogTotalSelector, broadcastsStatusLogSelector } from '../../BroadcastsSelector';
import { getBroadcastsLog } from '../../BroadcactsSlice';
import { broadcastLogPageLimit } from '../../utils';
import { getPageLimit } from '../../../../components/Paginator/utils';
import Paginator from '../../../../components/Paginator';
import LogList from '../LogList';
import loader from '../../../../assets/grid.svg';
import './BroadcastsLog.scss';


function BroadcastsLog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const total = useSelector(broadcastsLogTotalSelector);
  const status = useSelector(broadcastsStatusLogSelector);

  const onUpdate = (page: number, limit: number) => {
    dispatch(getBroadcastsLog({
      companyId: currentCompany.id, limit, offset: (limit * (page - 1)) }));
  };

  return (
    <div className='broadcastsLog'>
      <h5>{t('broadcasts.log.title')}</h5>
      <div className='log_list'>
        <div className='log_list__header smallText semibold'>
          <div>{t('broadcasts.params.name_date')}</div>
          <div>{t('broadcasts.params.connection')}</div>
          <div>{t('broadcasts.params.recipients')}</div>
          <div>{t('broadcasts.params.delivered')}</div>
          <div>{t('broadcasts.params.read')}</div>
          <div>{t('broadcasts.params.status')}</div>
        </div>
        {status === 'load'
          ? <div className="load"><img src={loader} alt='loading...'/></div>
          : <LogList />}
      </div>
      {total > getPageLimit(broadcastLogPageLimit) && 
        <Paginator totalCount={total} pageSelect={onUpdate} pageLimitStoreIndex={broadcastLogPageLimit} />}
    </div>
  );
}

export default BroadcastsLog;
