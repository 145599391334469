import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { fetchTagsList, stashTag } from '../BroadcactsSlice';
import {
  broadcastsTagsHasMoreSelector, broadcastsTagsOffsetSelector, broadcastsTagsSelector
} from '../BroadcastsSelector';


const TAGS_LIMIT = 10;

export function useTags() {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const hasMore = useSelector(broadcastsTagsHasMoreSelector);
  const items = useSelector(broadcastsTagsSelector);
  const offset = useSelector(broadcastsTagsOffsetSelector);

  useEffect(() => {
    if (items.length === 0) {
      dispatch(fetchTagsList({ companyId: currentCompany.id, limit: TAGS_LIMIT }));
    }
  }, []);

  const onUpload = () => {
    dispatch(fetchTagsList({ companyId: currentCompany.id, limit: TAGS_LIMIT, offset }));
  };

  const onChoose = (id: number) => {
    dispatch(stashTag(id));
  };

  return { items, hasMore, onChoose, onUpload };
}
