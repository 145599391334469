import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { membersSelector } from '../../../Chat/companiesSelector';
import { broadcastsSourceSelector } from '../../BroadcastsSelector';
import { removeBroadcast } from '../../BroadcactsSlice';
import { IBroadcast } from '../../BroadcastsTypes';
import { BroadcastsServicesType } from '../../utils';
import ActionDialog from '../../../../components/Modals/ActionDialog';
import ItemChangeAt from '../ItemChangeAt';
import ItemConnection from '../../components/ItemConnection';
import ItemNameDate from '../ItemNameDate';
import './PlanItem.scss';


type PlanItemProps = {
  item: IBroadcast;
};

function PlanItem({ item }: PlanItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId/broadcasts');
  const members = useSelector(membersSelector);
  const user = members.find(member => member.id === item.createdBy);
  const connections = useSelector(broadcastsSourceSelector);
  const connection = connections.find(con => con.connectionId === item.connectionId);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const onPreview = () => {
    history.push({
      pathname: `${url?.url}/${item.id}`,
      state: item
    });
  };

  const onEdit = () => history.push({ pathname: `${url?.url}/edit/${item.id}` });

  const onDelete = () => {
    setDeleteOpen(true);
  };

  const deleteBroadcast = () => {
    if (url?.params.companyId) {
      dispatch(removeBroadcast({ companyId: Number(url.params.companyId), broadcastId: item.id }));
      setDeleteOpen(false);
    }
  };

  const getDeleteConfimMessage = () => t('broadcasts.delete.message', { broadcast: item.name });

  return (
    <>
    {deleteOpen &&
      <ActionDialog action={t('delete')} cancel={t('cancel')} onAction={deleteBroadcast} setIsOpen={setDeleteOpen}
        title={t('broadcasts.delete.caption')} message={getDeleteConfimMessage()} />}
      <div className='plan_list__item' onClick={onPreview}>
        <ItemNameDate name={item.name} date={item.plannedTime} />
        <ItemConnection name={connection?.name} service={connection?.type as BroadcastsServicesType} />
        <ItemChangeAt name={user?.displayName} date={item.updatedAt} onEdit={onEdit} onDelete={onDelete} />
      </div>
    </>
  );
}

export default PlanItem;
