import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ParamsType, WabaTemplateMessageParams } from "./data";


export function useParamsSchema(params: WabaTemplateMessageParams) {
  const { t } = useTranslation();
  const schemas: Array<Record<string, yup.AnyObjectSchema | yup.ArraySchema<yup.AnyObjectSchema> | yup.AnySchema>> = [];
  
  const arraySchema = (list: ParamsType[] | undefined) => {
    return yup.array().min(list?.length ?? 1, t(t('error.form.empty_field'))).of(yup.object().shape({
      value: yup.string().required(t('error.form.empty_field')).nullable(true).transform((_, val) => (val !== "" ? val : null)),
    }));
  };
  
  Object.keys(params).forEach(key => {
    if (key === 'attachment') {
      schemas.push({ attachment: yup.string().required(t('error.form.empty_field')) });
    }
    if (key === 'header') {
      schemas.push({ header: arraySchema(params[key]) });
    }
    if (key === 'body') {
      schemas.push({ body: arraySchema(params[key]) });
    }
    if (key === 'buttons') {
      schemas.push({ buttons: arraySchema(params[key]) });
    }
    if (key === 'carousel') {
      schemas.push({ carousel: yup.array().min(params[key]?.length ?? 1, t('error.form.empty_field')).of(
        yup.object().shape({
          header: yup.string().required(t('error.form.empty_field')),
          body: arraySchema(params[key]?.[0]?.body),
          buttons: arraySchema(params[key]?.[0]?.buttons)
        })
      ) });
    }
  });

  const schema = yup.object().shape({
    ...(schemas.reduce((acc, schema) => ({ ...acc, ...schema }), {}))
  });

  return schema;
}
