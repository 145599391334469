import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { broadcastsListSelector, broadcastsStatusSelector } from './BroadcastsSelector';
import { getBroadcastsLog, getBroadcastsPlan, getSources } from './BroadcactsSlice';
import { getPageLimit } from '../../components/Paginator/utils';
import { broadcastLogPageLimit } from './utils';
import BroadcastsPlug from './BroadcastsPlug';
import BroadcastsView from './BroadcastsView';
import loader from '../../assets/grid.svg';
import './Broadcasts.scss';


function Broadcasts() {
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/broadcasts');
  const broadcasts = useSelector(broadcastsListSelector);
  const status = useSelector(broadcastsStatusSelector);
  const [load, setLoad] = useState<boolean>(true);

  useEffect(() => {
    setLoad((status === 'load'));
  }, [status]);

  useEffect(() => {
    if (url?.params.companyId) {
      const currentCompany = Number(url?.params.companyId);
      dispatch(getBroadcastsPlan({ companyId: currentCompany }));
      dispatch(getBroadcastsLog({ companyId: currentCompany, limit: getPageLimit(broadcastLogPageLimit) }));
      dispatch(getSources({ companyId: currentCompany }));
    }
  }, [url?.params.companyId]);

  if (load || status === 'load') {
    return (<div className='broadcasts'>
      <div className="load"><img src={loader} alt='loading...'/></div>
    </div>);
  }

  return (
    <div className='broadcasts'>
      {broadcasts && broadcasts.length > 0
        ? <BroadcastsView />
        : <BroadcastsPlug />
      }
    </div>
  );
}

export default Broadcasts;
