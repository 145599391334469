import axiosInstance from '../../../api/AxiosInstance';
import * as typ from './TagsTypes';


export const getTags = async (requestOptions: typ.TagsListRequestType): Promise<typ.Tag[]> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/tags/`, {
    params: { limit: requestOptions.limit, offset: requestOptions.offset },
  });

export const getTag = async (tagId: number, companyId: number): Promise<typ.Tag> =>
  await axiosInstance.get(`/companies/${companyId}/tags/${tagId}`);

export const createTag = async (requestOptions: typ.CreateTag): Promise<typ.Tag> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/tags/`, { ...rest });
};

export const updateTag = async (requestOptions: typ.UpdateTag): Promise<typ.Tag> => {
  const { companyId, tagId, ...rest } = requestOptions;
  return await axiosInstance.put(`/companies/${companyId}/tags/${tagId}`, { ...rest });
}

export const deleteTag = async(tagId: number, companyId: number): Promise<typ.Tag> =>
  await axiosInstance.delete(`/companies/${companyId}/tags/${tagId}`);
