import { useEffect, useState } from 'react';
import './CreateEditShop.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useRouteMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { catalogsSelector } from '../../shopsSelector';
import { createShop, getCatalogs, updateShop } from '../../shopsSlice';
import { fetchConnectionsList } from '../../../../Settings/Connections/connectionsSlice';
import GoBackWrapper from '../../../../../components/GoBackWrapper';
import Button from '../../../../../components/Button/Button';
import Dropdown from '../../../../Settings/Dropdown/Dropdown';
import Input from '../../../../../components/Input';


type SubmitSchema = {
  name: string;
  catalogId: number;
  connectionId: number;
};

const defaultValues: SubmitSchema = {
  name: '',
  catalogId: 0,
  connectionId: 0,
};

const TELEGRAM_BOT = "telegram_bot";

const CreateEditShop = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const catalogsAndCount = useSelector(catalogsSelector);
  const connectionsTelegramBot = useAppSelector((state) => state.connections.connectionsStore[TELEGRAM_BOT]);
  const modeEdit = localStorage.getItem('editShop');

  const [clicked, setClicked] = useState<boolean>(false);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field'))
      .max(256, ({ max }) => t('error.form.max_length', { limit: max })),
    catalogId: yup.number().min(1, t('error.form.empty_field')).typeError(t('error.form.empty_field'))
      .required(t('error.form.empty_field')),
    connectionId: yup.number().min(1, t('error.form.empty_field')).typeError(t('error.form.empty_field'))
      .required(t('error.form.empty_field'))
  });

  const methods = useForm<SubmitSchema>({ mode: 'onSubmit', resolver: yupResolver(schema), defaultValues });

  const onSubmit = (data: SubmitSchema) => {
    if (params?.params?.companyId) {
      if (modeEdit) {
        setClicked(true);
        const convertModeEdit = JSON.parse(modeEdit);
        dispatch(updateShop({
          companyId: params.params.companyId,
          catalogId: data.catalogId,
          connectionId: data.connectionId,
          name: data.name,
          shopId: convertModeEdit.id,
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            push(`${params?.url}/shops`);
          }
        });
      } else {
        setClicked(true);
        dispatch(createShop({
          companyId: params.params.companyId,
          catalogId: data.catalogId,
          connectionId: data.connectionId,
          name: data.name,
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
            push({ pathname: `${params?.url}/shops`, state: res.payload.link });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (modeEdit) {
      const { name, connectionId, catalogId } = JSON.parse(modeEdit);
      methods.reset({ name, catalogId, connectionId });
    }
  }, [modeEdit]);

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(getCatalogs({ companyId: params.params.companyId }));

      dispatch(fetchConnectionsList({
        companyId: Number(params?.params.companyId),
        types: TELEGRAM_BOT
      }));
    }
    return () => {
      localStorage.removeItem('editShop');
    };
  }, []);

  return (
    <div className='createEditShop'>
      <GoBackWrapper title={t(`${modeEdit ? 'sales.shops.editShop' : 'sales.shops.createShop'}`)}>
        <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input name='name' label={t('sales.shops.nameShop')} />
            <Dropdown name='catalogId' label={t('sales.shops.selectCatalog')} labelKey='name' valueKey='id' 
              items={catalogsAndCount?.items || []} placeholder={t('select')} />
            <Dropdown name='connectionId' label={t('sales.shops.selectConnection')} labelKey='name' valueKey='id'
              items={connectionsTelegramBot} placeholder={t('select')} />
            <Button type='submit' color='orange' textType='regular'
              text={modeEdit ? t('save_edit') : t('sales.shops.createShop')}
              disabled={!methods.formState.isDirty || clicked} />
          </form>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
};

export default CreateEditShop;
