import React from 'react';
import './PromotionBanner.scss';
import promotionLogo from '../../../../../assets/promotion-logo.png';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEvent } from '../../../../../utils/event';


type Props = {
  isRolledUp?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const PromotionBanner = ({ isRolledUp=false, setIsOpen }: Props) => {
  const { t }  = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const history = useHistory();
  const event = useEvent();

  const onPromotion = () => {
    if (event.discount) {
      setIsOpen && setIsOpen(false);
      history.push({
        pathname: `${url?.url}/settings/subscriptions`,
        state: true,
      });
    } else if (event.link.length > 0) {
      window.open(event.link, '_blank', 'noreferrer');
    }
  };

  return (
    event.active && event.banner
    ? <div className='promotionBanner'>
      {isRolledUp
        ? <img className='promotionBanner__logo' alt='promotion logo' onClick={onPromotion} src={promotionLogo} />
        : <img className='promotionBanner__banner' alt='promotion banner' onClick={onPromotion}
            src={event.banner} />}
      {!isRolledUp && event.link.length < 0 &&
        <a href={event.link} rel="noreferrer" target='_blank'>{t('navbar.promotion')}</a>}
      </div>
    : <></>
  );
};

export default PromotionBanner;
