import React, { useEffect, useRef, useState } from 'react';
import './RequisitesForm.scss';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMobile, useOutsideClick  } from '../../../../../components/utils/hooks';
import { addAlertWithCustomText, AlertStyle } from '../../../../../components/Alert/alertSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross.svg';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';


type RequisiteSchema = { name: string; tin: string };

type Props<T> = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  makeRequisite: (
    requisite: { company_id: number } & RequisiteSchema,
    editData?: { company_id: number; requisite_id: number } & RequisiteSchema
  ) => Promise<boolean>;
  editData?: T;
};

const RequisitesForm = <T extends { id: number; name: string; tin: string }>({
  setIsOpen, makeRequisite, editData }: Props<T>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<SVGSVGElement>(null);
  const isMobile = useMobile();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    tin: yup.string().required(t('error.form.empty_field')).matches(/^(?:\d{10}|\d{12})$/, t('requisites.tin_error'))
      .min(10, t('error.form.min_length', { limit: 10 })).max(12, t('error.form.max_length', { limit: 12 })),
  });

  const methods = useForm<RequisiteSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '', tin: '' },
  });

  useEffect(() => {
    if (editData) {
      methods.reset({ name: editData.name, tin: editData.tin });
    }
  }, []);

  const onClose = () => {
    setIsOpen(false);
    setIsSuccessSubmit(false);
  };

  function showAlert(message: string, type: AlertStyle) {
    dispatch(addAlertWithCustomText({ message, type }));
  }

  const onSubmit = (data: RequisiteSchema) => {
    if (editData) {
      makeRequisite(
        { company_id: currentCompany.id, ...data },
        { company_id: currentCompany.id, requisite_id: editData.id, ...data }
      ).then((res) => {
        if (res) {
          showAlert(t('requisites.actions.success_edit'), 'info');
          setIsOpen(false);
        } else {
          showAlert(t('requisites.actions.error_edit'), 'alarm');
        }
      });
    } else {
      makeRequisite({ company_id: currentCompany.id, ...data }).then((res) => {
        if (res) {
          showAlert(t('requisites.actions.success_create'), 'info');
          setIsSuccessSubmit(true);
        } else {
          showAlert(t('requisites.actions.error_create'), 'alarm');
        }
      });
    }
  };

  useOutsideClick(contentRef, onClose, triggerRef);

  if (isSuccessSubmit) {
    return (
      <div className="requisitesForm" ref={contentRef}>
        <div className="requisitesForm__header">
          <h3>{t('requisites.actions.success_create')}</h3>
          <CloseIcon ref={triggerRef} onClick={onClose} />
        </div>
        <p className="smallText">{t('requisites.actions.submit_text')}</p>
        <div className="requisitesForm__footer">
          <Button text={t('confirm')} color="orange" textType={isMobile ? 'regular' : 'small'} onClick={onClose} />
        </div>
      </div>
    );
  }

  return (
    <div className="requisitesForm" ref={contentRef}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="requisitesForm__header">
            <h3>{editData ? t('requisites.title_edit') : t('requisites.title_create')}</h3>
            <CloseIcon ref={triggerRef} onClick={onClose} />
          </div>
          <div className="requisitesForm__content">
            <Input name="name" label={t('requisites.name')} />
            <Input name="tin" label={t('requisites.tin')} />
          </div>
          <div className="requisitesForm__buttons">
            <Button type="submit" color="orange" textType={isMobile ? 'regular' : 'small'} text={t('save')}  />
            <Button type="button" color="white" textType={isMobile ? 'regular' : 'small'} text={t('cancel')}
              onClick={onClose} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RequisitesForm;
