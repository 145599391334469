import { useEffect, useState } from 'react';
import './BroadcastsForm.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { broadcastsStatusSelector } from "../BroadcastsSelector";
import {
  useBroadcastRecipients, useContactList, useContidionList,
  useSources, useSubmitBroadcast, useMessage, useCheckSource, useBroadcastItem,
} from '../api';
import { ContactSourceType, BroadcastSchema, ErrorStateType, BroadcastMessage } from '../api/types';
import {
  BroadcastsServicesType, calcDuration, checkIConditions, getSelectConnectionType,
  ServiceContactType, servicesContacts, SourceType, parseDate } from '../utils';
import { IBroadcastMessage, IConditions } from '../BroadcastsTypes';
import { usePhoneSchema } from '../../../components/PhoneInput/utils';
import GoBackWrapper from '../../../components/GoBackWrapper/GoBackWrapper';
import Input from '../../../components/Input/Input';
import Dropdown from '../../Settings/Dropdown/Dropdown';
import Button from '../../../components/Button/Button';
import SwitchTabs from '../../../components/SwitchTabs';
import ConditionsForm from '../components/ConditionsForm';
import ContactsForm from './ContactsForm';
import MessagePlug from './MessagePlug';
import TextMessage from './TextMessage';
import DelayForm from './DelayForm';
import NumbersOptions from './NumbersOptions';
import BroadcastAlertWarn from './BroadcastAlertWarn';
import WabaTemplateMessage from './WabaTemplateMessage';
import Loader from '../../../components/Loader/Loader';
import { ReactComponent as SmallLoader } from '../../../assets/small-loader.svg';


type ContactSourceTabType = {
  name: string;
  id: ContactSourceType;
};

const contactSourceTabs: ContactSourceTabType[] = [
  { name: 'broadcasts.form.contacts_radist', id: 'radist' }, { name: 'broadcasts.form.contacts_list', id: 'client' }];

type MessageFormType = {
  setBroadcastMessage: React.Dispatch<React.SetStateAction<BroadcastMessage | undefined>>;
  broadcastItemMessage?: IBroadcastMessage;
  selectedSource?: SourceType | null;
  errorState?: ErrorStateType;
};

const messageForm: Record<BroadcastsServicesType, React.FunctionComponent<MessageFormType>> = {
  telegram: TextMessage,
  telegram_bot: TextMessage,
  waba: WabaTemplateMessage,
  whatsapp: TextMessage
};

const optionsForm: Record<ServiceContactType, JSX.Element | null> = {
  phone: <NumbersOptions name='params.from_active_chat' />,
  username: null
};

function BroadcastsForm() {
  const { t } = useTranslation();
  const { goBack, push } = useHistory();
  const sources = useSources();
  const onSubmitBroadcast = useSubmitBroadcast();
  const status = useSelector(broadcastsStatusSelector);
  const url = useRouteMatch<{ broadcastId: string }>('/companies/:companyId/broadcasts/edit/:broadcastId');
  const company = useSelector(currentCompanySelector);
  const { state } = useLocation<number>();
  const broadcastItem = useBroadcastItem(Number(url?.params.broadcastId) || state);
  const [initConditions, setInitConditions] = useState<IConditions>();
  const { conditions, isChangedCondtitions, onInsert, onDelete, operation, onOperation } = 
    useContidionList(initConditions);
  const { broadcastMessage, setBroadcastMessage, isChangedMessage } = useMessage();
  const [initRecipients, setInitRecipients] = useState<string[]>();
  const { contacts, setContacts, isChangedContacts } = useContactList(initRecipients);
  const [submitText, setSubmitText] = useState<string>(t('broadcasts.form.run'));
  const [contactSource, setContactSource] = useState<ContactSourceType>('radist');
  const [selectedService, setSelectedService] = useState<BroadcastsServicesType>('' as BroadcastsServicesType);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedSource, setSelectedSource] = useState<SourceType | null>(null);
  const { checkSource, enoughDialogs } = useCheckSource(selectedSource);
  const [recipientsEstimated, setRecipientsEstimated] = useState<number>(0);
  const [errorState, setErrorState] = useState<ErrorStateType>();
  const [loadForm, setLoadForm] = useState<boolean>(true);
  const phoneSchema = usePhoneSchema({});
  const schema = yup.object().shape({
    ...{
      name: yup.string().required(t('error.form.empty_field')).max(50, t('error.form.max_length', { limit: 50 })),
      connection_id: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      params: yup.object().shape({
        from_active_chat: yup.boolean()
      }),
      planned_time: yup.date().nullable()
    }, ...phoneSchema
  });

  const switchTabs = contactSourceTabs.map((tab) => ({ tabName: t(tab.name), tabId: tab.id }));

  const methods = useForm<BroadcastSchema>({ mode: 'onChange', resolver: yupResolver(schema) });

  useBroadcastRecipients({
    contactSource,
    connectionId: selectedSource?.id,
    fromActiveChat: useWatch({ control: methods.control, name: 'params.from_active_chat' }),
    conditionsParams: { conditions, logicOperation: operation },
    recipients: contacts.recipients
  }).then((res) => setRecipientsEstimated(res));

  useEffect(() => {
    if (url?.params.broadcastId || state) {
      if (broadcastItem) {
        if (checkIConditions(broadcastItem.params.conditions)) {
          setContactSource('radist');
          setInitConditions(broadcastItem.params.conditions);
        } else if (broadcastItem.params.conditions?.chats) {
          setContactSource('client');
          setInitRecipients(broadcastItem.params.conditions.chats);
        }
        methods.reset({
          name: broadcastItem.name,
          planned_time: broadcastItem.plannedTime ? parseDate(broadcastItem.plannedTime) : undefined,
          connection_id: broadcastItem.connectionId,
          params: { from_active_chat: broadcastItem.params.fromActiveChat || true }
        });
        if (state) methods.setValue('planned_time', undefined, { shouldDirty: true } );
        setLoadForm(false);
      }
      return;
    }
    setLoadForm(false);
  }, [broadcastItem, url?.params.broadcastId, state]);

  const selectedConnection = useWatch({ control: methods.control, name: 'connection_id', defaultValue: undefined });
  const plannedTime = useWatch({ control: methods.control, name: 'planned_time' });

  useEffect(() => {
    let connectionId = selectedConnection;
    if (!connectionId) connectionId = methods.getValues('connection_id');
    if (sources && connectionId) {
      setSelectedService(getSelectConnectionType(sources, connectionId) as BroadcastsServicesType);
      setSelectedSource(sources.find(item => item.id === connectionId) || null);
      setErrorState({ ...errorState, recipients: false, text: false, recipientsRadist: false, template: false });
    }
  }, [sources, selectedConnection, initConditions, initRecipients]);

  useEffect(() => {
    checkSource();
  }, [selectedSource])

  useEffect(() => {
    // if source for broadcast is telegram bot then only radist web contacts should be choosen
    if (selectedService === 'telegram_bot') setContactSource('radist');
  }, [selectedService])

  useEffect(() => {
    let caption = t('broadcasts.form.run');
    if (methods.getValues('planned_time')) caption = t('broadcasts.form.delay');
    setSubmitText(caption);
  }, [plannedTime, initConditions, initRecipients]);

 const onContactsSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    setContactSource(e.currentTarget.id as ContactSourceType);
  };

  const gotoList = () => push(`/companies/${company.id}/broadcasts`);

  const onSubmit = async (data: BroadcastSchema) => {
    if (!broadcastMessage) return
    setChecked(true);
    await onSubmitBroadcast({
      data,
      contactSource,
      operation,
      conditions,
      recipients: contacts.recipients,
      broadcastId: url?.params.broadcastId ? Number(url.params.broadcastId) : undefined,
      broadcastMessage,
      recipientsEstimated
    }).then(response => {
      if (response.status) gotoList();
      setChecked(response.status);
      setErrorState(response.error);
    });
  };

  const MessageComponent = messageForm[selectedService];

  const submitDisabled = !methods.formState.isDirty && !isChangedCondtitions
    && !isChangedContacts && !isChangedMessage || (!enoughDialogs && !methods.getValues('planned_time'));

  if (loadForm || (status === 'load' && (url?.params.broadcastId || state))) {
    return <Loader />;
  }

  return (
    <div className='broadcastsForm'>
      <GoBackWrapper title={url?.params.broadcastId ? t('broadcasts.edit') : t('broadcasts.form.title')}>
        <div className='broadcastsForm__content'>
          <FormProvider {...methods}>
            <BroadcastAlertWarn connectionId={broadcastItem?.connectionId} selectedSource={selectedSource} />
            <Input label={t('broadcasts.form.name')} name='name' />
            {sources &&
              <Dropdown label={t('broadcasts.form.source')} name='connection_id'
                items={sources} labelKey='name' valueKey='id' placeholder={t('broadcasts.form.source_placeholder')} />
            }
            <p className='regularText middle'>{t('broadcasts.form.contacts')}</p>
            {selectedService !== 'telegram_bot' &&
              <SwitchTabs tabs={switchTabs} selectedTab={contactSource} onSelectHandler={onContactsSelect} />}
            { contactSource === 'radist'
              ? <ConditionsForm conditions={conditions} onInsert={onInsert} onDelete={onDelete}
                  operation={operation} onOperation={onOperation} recipientsNumber={recipientsEstimated}
                  service={selectedService} errorState={errorState} />
              : <ContactsForm selectedService={selectedService} contacts={contacts}
                  setContacts={setContacts} errorState={errorState} />
            }
            {selectedService && contactSource === 'radist' && optionsForm[servicesContacts[selectedService]]}
            {selectedService
              ? <MessageComponent selectedSource={selectedSource} broadcastItemMessage={broadcastItem?.message}
                  setBroadcastMessage={setBroadcastMessage} errorState={errorState} />
              : <MessagePlug />
            }
            <div className='broadcastsForm__duration'>
              <p className='regularText middle'>{t('broadcasts.form.duration_lable')}</p>
              <h1>{calcDuration(recipientsEstimated)} {t('broadcasts.form.duration_unit')}</h1>
            </div>
            <DelayForm name='planned_time' />
            <div className={`broadcastsForm__buttons ${checked  ? 'check' : ''}`}>
              <Button type='submit' color='orange' textType='regular' text={submitText} disabled={submitDisabled}
                onClick={methods.handleSubmit(onSubmit)}
                image={methods.formState.isSubmitting ? <SmallLoader /> : <></>} />
              <Button type='button' color='white' textType='regular' text={t('cancel')} onClick={goBack} />
            </div>
          </FormProvider>
        </div>
      </GoBackWrapper>
    </div>
  );
}

export default BroadcastsForm;
