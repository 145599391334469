import axiosInstance from "../../api/AxiosInstance";
import * as typ from './BroadcastsTypes';


export const getBroadcastsList = async (requestOptions: typ.BroadcastsRequest): Promise<typ.IBroadcasts> => {
  let repeat: boolean | null | undefined = false;
  if (requestOptions.statuses && Array.isArray(requestOptions.statuses)) repeat = null;
  return await axiosInstance.get(`/companies/${requestOptions.companyId}/broadcasts/`, {
    params: {
      limit: requestOptions.limit, offset: requestOptions.offset, statuses: requestOptions.statuses
    },
    paramsSerializer: {
      indexes: repeat
    }
  });
};

export const getBroadcastItem = async (requestOptions: typ.IBroadcastRequest): Promise<typ.IBroadcast> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/broadcasts/${requestOptions.broadcastId}`);

export const postBroadcast = async (requestOptions: typ.BroadcastCreateRequest): Promise<typ.IBroadcast> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/broadcasts/`, { ...rest });
};

export const patchBroadcast = async (
  requestOptions: typ.BroadcastUpdateRequest | typ.BroadcastStopRequest): Promise<typ.IBroadcast> => {
  const { companyId, broadcastId, ...rest } = requestOptions;
  return await axiosInstance.patch(`/companies/${companyId}/broadcasts/${broadcastId}`, { ...rest });
};

export const getRecipientsCount = async (
  requestOptions: typ.BroadcastRecipientsRequest): Promise<typ.IBroadcactRecipients> => {
  const { companyId, connectionId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/broadcasts/estimated_recipients_count/`,
  { connection_id: connectionId, params: { ...rest } });
};

export const getBroadcastsStatistics = async (requestOptions: typ.BroadcastsStatisticsRequest):
  Promise<typ.IBroadcastsStatistics> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/broadcasts/${requestOptions.broadcastsId}/statistics/`, {
    params: {
      limit: requestOptions.limit, offset: requestOptions.offset
    }
  });

export const getBroadcastSources = async (requestOptions: { companyId: number }): Promise<typ.ISourceType[]> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/messaging/chats/sources/`);

export const deleteBroadcast = async (requestOptions: typ.IBroadcastRequest): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOptions.companyId}/broadcasts/${requestOptions.broadcastId}`);

export const getBroadcastDialogsCounters = async (companyId: number): Promise<typ.DialogsCountsType[]> =>
  await axiosInstance.get(`/companies/${companyId}/messaging/dialogs/counters/`);
