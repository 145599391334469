import React, { useEffect, useRef, useState } from 'react';
import './TextMessage.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseEmoji } from 'emoji-mart';
import { useDispatch } from 'react-redux';
import { getMessageViaTypes } from '../../utils';
import { BroadcastMessage, BroadcastMessageFiles, ErrorStateType, TextSchema } from "../../api/types";
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import EmojiComponent from '../../../../components/EmojiComponent/EmojiComponent';
import FilePreview from './FilePreview';
import FileIconComponent from './FileIconComponent/FileIconComponent';
import { IBroadcastMessage } from '../../BroadcastsTypes';


type TextMessageProps = {
  setBroadcastMessage: React.Dispatch<React.SetStateAction<BroadcastMessage | undefined>>;
  broadcastItemMessage?: IBroadcastMessage;
  errorState?: ErrorStateType;
};

function TextMessage({ setBroadcastMessage, broadcastItemMessage, errorState }: TextMessageProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickerTriggerButtonRef = useRef<SVGSVGElement>(null);
  const [picker, setPicker] = useState(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [attachedUrl, setAttachedUrl] = useState<string>();
  const [fileType, setFileType] = useState('');

  const schema = yup.object().shape({
    text: yup.string().required(t('error.form.empty_field')).when('fileType', {
       is: '',
       then: yup.string().max(8192, ({ max }) => t('error.form.max_length', { limit: max })) }),
       otherwise: yup.string().max(1024, ({ max }) => t('error.form.max_length', { limit: max }))
  });

  const methods = useForm<TextSchema>({ mode: 'onChange', resolver: yupResolver(schema) });

  const text = useWatch({ control: methods.control, name: 'text' });
  const isError = methods.formState.errors.text || (!text && errorState?.text);

  useEffect(() => {
    if (fileType === '') {
      setBroadcastMessage({ message_type: 'text', text });
    } else {
      setBroadcastMessage({ message_type: fileType === 'application' ? 'file': fileType as BroadcastMessageFiles,
        caption: text, file: attachedFile || attachedUrl });
    }
  }, [text, attachedFile, attachedUrl, fileType]);

  useEffect(() => {
    if (broadcastItemMessage) {
      const messageContent = getMessageViaTypes(broadcastItemMessage);
      if (messageContent) {
        if (messageContent.type) setFileType(messageContent.type);
        if (messageContent.url) setAttachedUrl(messageContent.url);
        if (messageContent.caption) methods.reset({ text: messageContent.caption });
      }
    }
  }, [broadcastItemMessage]);

  const triggerPicker = () => {
    setPicker(!picker);
  };

  const onSelectEmoji = (emoji: BaseEmoji) => {
    const value = methods.getValues('text');
    methods.setValue('text', `${value}${emoji.native}`)
  };

  const onAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size <= 104857600) {
        setFileType(e.target.files[0].type.split('/')[0]);
        setAttachedFile(e.target.files[0]);
      } else {
        setAttachedFile(undefined);
        dispatch(addAlertWithCustomText({ message: t('broadcasts.error.not_correct_size'), type: 'alarm' }));
      }
    }
  };

  return (
    <div className="textMessage">
      <h4>{t('broadcasts.form.message_lable')}</h4>
      <form {...methods}>
        <div className={`textMessage__field ${isError ? 'error' : ''}`}>
          <textarea placeholder={t('chats.chat.message')} {...methods.register('text')}
            maxLength={attachedFile ? 1024 : 8192} />
          {(attachedFile || attachedUrl) && (
            <FilePreview attachedFile={attachedFile} setAttachedFile={setAttachedFile}
              fileType={fileType} setFileType={setFileType} attachedUrl={attachedUrl}
              setAttachedUrl={setAttachedUrl} />
          )}
          <div className="textMessage__field_bottom">
            <EmojiComponent picker={picker} setPicker={setPicker} onSelect={onSelectEmoji}
              pickerRef={pickerTriggerButtonRef} onClick={triggerPicker} />
            {!attachedUrl && !attachedFile && (
              <>
                <FileIconComponent onAttachmentFile={onAttachmentFile} />
                <p className="limitFile">{t('error.limit_file')}</p>
              </>
            )}
          </div>
        </div>
      </form>
      {(!text && errorState?.text) ? <p className="textMessage__field__errorMessage">{t('error.form.empty_field')}</p> :
        <ErrorMessage errors={methods.formState.errors} name="text"
          render={({ message }) => <p className="textMessage__field__errorMessage">{message}</p>}
        />}
    </div>
  );
}

export default TextMessage;
