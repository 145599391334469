import { useMemo, useState } from 'react';
import './StatisticPreview.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { ConditionsType, IChats, IConditions, IStatistic } from '../../BroadcastsTypes';
import { formatNumberLocale } from '../../../../utils/utils';
import { getRecipientsCount } from '../../BroadcastsAPI';
import { useBroadcastItem } from '../../api';
import { calcPercentage } from '../../utils';


type Props = {
  statistic: string;
  arrStatistics: IStatistic;
};

function checkConditions(conditions: IConditions | IChats): boolean {
  return conditions && Object.keys(conditions).includes('conditions');
}

const StatisticPreview = ({ statistic, arrStatistics }: Props) => {
  const { t, i18n } = useTranslation();
  const url = useRouteMatch<{ companyId: string; broadcastId: string }>('/companies/:companyId/broadcasts/:broadcastId');
  const broadcastItem = useBroadcastItem();
  const [recipientsCount, setRecipientsCount] = useState<number>();

  useMemo(async () => {
    let conditions: ConditionsType | IChats;
    if (broadcastItem && broadcastItem.params.conditions) {
      if (checkConditions(broadcastItem.params.conditions)) {
        const broadcastConditions = broadcastItem.params.conditions as IConditions;
        conditions = {
          logic_operation: broadcastConditions.logicOperation,
          conditions: broadcastConditions.conditions.map(cond => ({
            comparison: cond.comparison,
            condition_type: cond.conditionType,
            value: cond.value
          }))
        };
      } else {
        const broadcastConditions = broadcastItem.params.conditions as IChats;
        conditions = { chats: broadcastConditions.chats } ;
      }
      const response = await getRecipientsCount({
        companyId: Number(url?.params.companyId),
        connectionId: broadcastItem.connectionId,
        from_active_chat: broadcastItem.params.fromActiveChat,
        conditions
      });
      setRecipientsCount(response.estimatedRecipientsCount);
    }
  }, []);

  const statisticNum: Record<string, number> = {
    recipients: (broadcastItem?.status === 'PENDING' ? recipientsCount : arrStatistics.total) ?? 0,
    delivered: arrStatistics.delivered,
    read: arrStatistics.read,
  };

  const percentage = calcPercentage(arrStatistics.total, statisticNum[statistic]);

  return (
    <div className="statisticPreview">
      <div>
        <h4>{t(`broadcasts.params.${statistic}`)}</h4>
        <div className={`statisticPreview__number ${statistic}`}>{statisticNum[statistic]}</div>
      </div>
      {statistic !== 'recipients' && percentage !== undefined &&
      <div className="statisticPreview__percent">{formatNumberLocale(percentage, 1, i18n.language)}%</div>}
    </div>
  );
};

export default StatisticPreview;
