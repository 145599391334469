import { useTranslation } from "react-i18next";
import { isTodayBetween } from "./getDateTime/getDateTime";
import happyNewYearRu from '../assets/happy-new-year-ru.png';
import happyNewYearEn from '../assets/happy-new-year-en.png';
import happyNewYearEs from '../assets/happy-new-year-es.png';


const eventStart = '2024-12-19T00:01:00';
const eventEnd = '2025-01-07T23:59:00';

type eventDetails = {
  active: boolean;
  discount: boolean;
  link: string;
  banner: string;
};

type PromotionType = {
  image: string;
  link: string;
};

const promotionDetails: Record<string, PromotionType> = {
  ru: { image: happyNewYearRu, link: '' },
  en: { image: happyNewYearEn, link: '' },
  es: { image: happyNewYearEs, link: '' },
};

export function useEvent(): eventDetails {
  const { i18n } = useTranslation();

  return {
    active: isTodayBetween(eventStart, eventEnd),
    banner: promotionDetails[i18n.language].image,
    discount: false,
    link: promotionDetails[i18n.language].link
  };
}
